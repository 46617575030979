import { StyleSheet, Text, View } from "@react-pdf/renderer";

export const ProgressBar = ({
  label,
  percentage,
  color = "#7C32C9",
  weight,
}) => {
  const styles = StyleSheet.create({
    progressContainer: {
      flexDirection: "row-reverse",
      alignItems: "center",
      marginVertical: 1,
      width: "100%",
      padding: 5,
      backgroundColor: "#7b32c90e",
      border: "1px solid #7C32C9 ",
      borderRadius: 5,
    },
    progressLabel: {
      width: "30%",
      fontSize: 10,
      textAlign: "right",
      paddingLeft: 5,
    },
    progressWeightLabel: {
      width: "10%",
      fontSize: 8,
      textAlign: "right",
      paddingLeft: 5,
    },
    progressBarWrapper: {
      width: "55%",
      backgroundColor: "transparent",
      borderRadius: 10,
      height: 12,
      overflow: "hidden",
      transform: "scaleX(-1)",
    },
    progressBar: {
      height: "100%",
      borderRadius: 10,
      transform: "scaleY(-1)",
    },
    progressText: {
      fontSize: 10,
      marginRight: 5,
      width: "10%",
      textAlign: "center",
    },
  });

  return (
    <View style={styles.progressContainer}>
      <Text style={styles.progressLabel}>{label}</Text>

      <Text style={styles.progressWeightLabel}>{weight}%</Text>

      <View style={styles.progressBarWrapper}>
        <View
          style={[
            styles.progressBar,
            { width: `${percentage}%`, backgroundColor: color },
          ]}
        />
      </View>
      <Text style={styles.progressText}>{`${percentage}%`}</Text>
    </View>
  );
};
