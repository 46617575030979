export function showRequirement(requirement) {
  // support for requirements without subStandardRequirements
  if(requirement.expertSubRequirementAnswers) {
    requirement.subStandardRequirements = requirement.expertSubRequirementAnswers;
  }

  if (
    !requirement.subStandardRequirements ||
    requirement.subStandardRequirements.length === 0
  ) {
    return (
      (requirement.decision == 0 || !requirement.decision) &&
      requirement.donotApply == false
    );
  } else {
    return !!(
      (requirement.decision == 0 || !requirement.decision) &&
      requirement.donotApply == false &&
      requirement.subStandardRequirements.find(
        (subReq) =>
          subReq.donotApply == false &&
          (subReq.decision == 0 || !subReq.decision)
      )
    );
  }
}


export function getStandardProofs(agencyAnswer){
  return agencyAnswer?.result?.surveyAgencyAnswer?.agencyProofAnswers.map((item => {
    return  {
      ...item,
      fileSize: agencyAnswer.result.standardProofs.find((proof) => proof.id === item.standardProofId)?.fileSize,
      title: agencyAnswer.result.standardProofs.find((proof) => proof.id === item.standardProofId)?.title,
      standardProofRequirements: agencyAnswer.result.standardProofs.find((proof) => proof.id === item.standardProofId)?.standardProofRequirements,
    }
  })) ?? []
}


export function getStandardRequirements(agencyAnswer){
  return agencyAnswer?.result?.surveyAgencyAnswer?.agencyRequirementAnswers.map((item => {
    const allSubReqs = (agencyAnswer?.result?.standardRequirements.map((req) => req.subStandardRequirements) ?? []).flat()
    return  {
      ...item,
      title: item.standardRequirementTitle,  
      subStandardRequirements: item.agencySubRequirementAnswers.map((subReq) => {
        const standardSubReq = allSubReqs.find((req) => req.id === subReq.subStandardRequirementId)
        return {
          ...subReq,
          title: standardSubReq?.title ?? '',
        }
      }),
    }
  })) ?? []
  console.log("🚀 ~ getStandardRequirements ~ agencyAnswer:", agencyAnswer)
}


export function hasAtLeastOneReOpenRequirement(item, reOpenRequirementIds) {
  return item.standardProofRequirements?.some((req) =>
    reOpenRequirementIds.includes(req.standardRequirementId)
  );
}


export function getReOpenRequirementIds(standardRequirementsPhaseThree){
  return standardRequirementsPhaseThree?.result?.standardRequirements?.map(
    (requirement) => requirement.id
  ) ?? []
}
