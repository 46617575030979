import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../services/Request";
import environment from "../../services/environment";
import { useContext } from "react";
import { StageContext } from "../../contexts/stageContext";

export const useGetAgenciesAnswersForStandard = ({
  currentPage,
  standardId,
  expertId,
  reviewerId,
  pocOfficerId,
  filterValue,
  stageId,
}) => {
  // const surveyId = localStorage.getItem("surveyId");

  const queryClient = useQueryClient();
  const { currentStage } = useContext(StageContext);

  const { data, isLoading: isGettingAgenciesAnswers } = useQuery({
    queryKey: [
      "AgenciesAnswersForStandard",
      currentPage,
      standardId,
      filterValue,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetFinalStandardAnswersForAgency, {
        KeyWord: filterValue,
        StandardId: standardId,
        ExpertId: expertId,
        ReviewerId: reviewerId,
        POCOfficerId: pocOfficerId,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
        stageId,
      }),
  });

  const agenciesAnswers = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  return {
    queryClient,
    agenciesAnswers,
    totalCount,
    totalPages,
    data,
    isGettingAgenciesAnswers,
  };
};
