import { Badge, Progress } from "flowbite-react";
import React, { useContext, useEffect, useState } from "react";
// import Card from "../Card";
import NewCard from "../../../../components/dashboard/NewCard";
import environment from "../../../../services/environment";
import NewTable from "../../../../components/NewTable";
import { StageContext } from "../../../../contexts/stageContext";
import { formateDate } from "../../../../helpers/dateFormat";
import { axiosGetRequest } from "../../../../services/Request";
import { useAccountMangerDashboardContext } from "../../../../contexts/AccountMangerDashboardContext";

const EndoscopesCard = ({ title }) => {
  // /api/services/app/AgencyStatistics/GetAgencyPerspectiveStatus
  const { currentStage , stageForDashboard } = useContext(StageContext);
  const phase2StageId = currentStage?.stageIds?.[1];
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [agencyPerspectiveStatus, setAgencyPerspectiveStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedAgency } = useAccountMangerDashboardContext();

  const agencyId = selectedAgency?.value;

  const getAgencyPerspectiveStatus = async (page) => {
    setLoading(true);

    if (currentStage?.id) {
      const res = await axiosGetRequest(
        environment.getAgencyPerspectiveStatus,
        {
          surveyStageId: stageForDashboard,
          agencyId: agencyId,
          MaxResultCount: 5,
          SkipCount: page * 5,
          stageId: phase2StageId,
        }
      );

      if (res?.success) {
        setAgencyPerspectiveStatus(res?.result?.items);
        setCount(res?.result?.totalCount);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getAgencyPerspectiveStatus(0);
  }, [stageForDashboard, agencyId]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAgencyPerspectiveStatus(newOffset);
  };

  const statusContent = (status) => {
    switch (status) {
      case 0:
        return (
          <Badge
            className="text-white font-medium bg-[#575757] pb-3 px-5 rounded-xl"
            size="sm"
          >
            لم يبدأ
          </Badge>
        );
      case 1:
        return (
          <Badge
            className="text-white font-medium bg-[#ffa92b] pb-3 px-5 rounded-xl"
            size="sm"
          >
            غير مكتمل
          </Badge>
        );
      case 2:
        return (
          <Badge
            className="text-white font-medium bg-[#1cc182] pb-3 px-5 rounded-xl"
            size="sm"
          >
            مكتمل
          </Badge>
        );

      default:
        return (
          <div>
            <span>—</span>
          </div>
        );
    }
  };

  const columns = [
    {
      name: "الاسم",
      selector: (row) => row.perspectiveTitle,
      header: "الاسم",
      accessorKey: "perspectiveTitle",
      footer: (props) => props.column.perspectiveTitle,
    },
    {
      name: "آخر تحديث",
      selector: (row) => row.lastAnswerDateOnARelatedStandard,
      header: "آخر تحديث",
      accessorKey: "lastAnswerDateOnARelatedStandard",
      footer: (props) => props.column.lastAnswerDateOnARelatedStandard,
      sortable: true,
      cell: (info) =>
        info.lastAnswerDateOnARelatedStandard
          ? formateDate(info.lastAnswerDateOnARelatedStandard)
          : "-",
    },
    {
      name: "الحالة",
      selector: (row) => row.status,
      header: "الحالة",
      accessorKey: "status",
      footer: (props) => props.column.status,
      cell: (info) => statusContent(info.status),
      center: true,
    },
    {
      name: "معدل الاكتمال",
      selector: (row) => row.completionPercentage,
      header: "معدل الاكتمال",
      accessorKey: "completionPercentage",
      footer: (props) => props.column.completionPercentage,
      cell: (info) => (
        <div className="px-3 py-4 w-full">
          <span className="text-sm text-[#A2A2A2]">{`${Math.round(
            info.completionPercentage
          ) || 0}%`}</span>

          <Progress
            theme={{
              color: {
                dark:
                  info.status == "0"
                    ? "bg-red-500"
                    : info.status == "1"
                    ? "bg-[#FFC000]"
                    : "bg-green",
              },
            }}
            color={"dark"}
            progress={info.completionPercentage}
          />
        </div>
      ),
    },
  ];

  return (
    <NewCard title={title || "نسبة الانجاز"}>
      <NewTable
        loading={loading}
        columns={columns}
        data={agencyPerspectiveStatus || []}
        pagination={true}
        totalPages={Math.ceil(count / 5)}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
};

export default EndoscopesCard;
