import AnswerDegreeItem from "./answerDegreeItem";
import { useAnswerContext } from "../answerContext";
import { StageContext } from "../../../../../../contexts/stageContext";
import { useContext } from "react";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../../services/Request";
import environment from "../../../../../../services/environment";

const ExpertAnswerDegrees = () => {
  const { entityId, standardId, programId } = useParams();
  const { newAgencyAnswer } = useAnswerContext();
  const { newExpertAnswer, expertDegree } = useAnswerContext();
  const { currentStage } = useContext(StageContext);
  const isForthStage = currentStage?.stageNumber === 4;
  const phase2StageId = currentStage?.stageIds?.[1];
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;

  const { data: phaseOneAgencyAnswer } = useQuery({
    queryKey: ["prepareStandardForAnswer", standardId, entityId],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[0],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!isForthStage,
  });

  const { data: expertAnswerOnPhase2 } = useQuery({
    queryKey: ["GetExpertAnswer", entityId, phase2StageId, standardId],
    queryFn: () =>
      axiosGetRequest(environment.getExpertAnswer, {
        agencyId: entityId,
        stageId: phase2StageId,
        standardId: standardId,
        expertId: localStorage.getItem("userId"),
      }),
    enabled: !!phase2StageId,
  });

  const { data: standardRequirementsPhaseThree } = useQuery({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: currentStage?.stageIds?.[2],
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const isNotReopenedStander = !!(
    standardRequirementsPhaseThree?.result?.standardRequirements?.length === 0
  );

  const disableSecondAuditPhase = isNotReopenedStander && !isConfidentialAgency;

  if (isForthStage && !disableSecondAuditPhase) {
    return (
      <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
        <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
          <AnswerDegreeItem
            degree={
              phaseOneAgencyAnswer?.result?.surveyAgencyAnswer
                ?.finalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              phaseOneAgencyAnswer?.result?.surveyAgencyAnswer
                ?.finalCommitmentLevel?.color || "#fff"
            }
            title="إجابة الجهة للمرحلة الأولى"
          />
          <AnswerDegreeItem
            degree={
              expertAnswerOnPhase2?.result?.lastAnswer
                ?.reviewerFinalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              expertAnswerOnPhase2?.result?.lastAnswer
                ?.reviewerFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة المدقق لمرحلة التحقق الأولى"
          />
          <AnswerDegreeItem
            degree={
              expertAnswerOnPhase2?.result?.lastAnswer
                ?.pocOfficerFinalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              expertAnswerOnPhase2?.result?.lastAnswer
                ?.pocOfficerFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة ضابط الاتصال لمرحلة التحقق الأولى"
          />
          <AnswerDegreeItem
            degree={
              expertAnswerOnPhase2?.result?.lastAnswer
                ?.expertFinalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              expertAnswerOnPhase2?.result?.lastAnswer
                ?.expertFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة الخبير لمرحلة التحقق الأولى"
          />
        </div>
        <div className="bg-white p-3 px-5 flex flex-wrap rounded-b-[9px] border-t-2">
          <AnswerDegreeItem
            degree={
              newExpertAnswer?.agencyFinalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              newExpertAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة الجهة لمرحلة إعادة فتح النظام"
          />
          <AnswerDegreeItem
            degree={
              newExpertAnswer?.reviewerFinalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              newExpertAnswer?.reviewerFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة المدقق لمرحلة التحقق النهائي"
          />
          <AnswerDegreeItem
            degree={
              newExpertAnswer?.pocOfficerFinalCommitmentLevel?.title ||
              "لا يوجد"
            }
            circleColor={
              newExpertAnswer?.pocOfficerFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة ضابط الاتصال لمرحلة التحقق النهائي"
          />
          <AnswerDegreeItem
            degree={newExpertAnswer?.checkedExpertDegree?.title || "لا يوجد"}
            circleColor={newExpertAnswer?.checkedExpertDegree?.color || "#fff"}
            title="إجابة الخبير لمرحلة التحقق النهائي"
          />
          <AnswerDegreeItem
            degree={
              expertDegree?.expertSelfEvaluationCommitmentLevel?.title ||
              "لا يوجد"
            }
            circleColor={
              expertDegree?.expertSelfEvaluationCommitmentLevel?.color || "#fff"
            }
            title="الدرجة المقترحة من النظام"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
        <AnswerDegreeItem
          degree={
            newExpertAnswer?.agencyFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newExpertAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الجهة للمرحلة الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertAnswer?.reviewerFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newExpertAnswer?.reviewerFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة المدقق لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertAnswer?.pocOfficerFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newExpertAnswer?.pocOfficerFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={newExpertAnswer?.checkedExpertDegree?.title || "لا يوجد"}
          circleColor={newExpertAnswer?.checkedExpertDegree?.color || "#fff"}
          title="إجابة الخبير لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            expertDegree?.expertSelfEvaluationCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            expertDegree?.expertSelfEvaluationCommitmentLevel?.color || "#fff"
          }
          title="الدرجة المقترحة من النظام"
        />
      </div>
    </div>
  );
};

export default ExpertAnswerDegrees;
