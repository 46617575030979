import { useContext, useEffect, useState } from "react";
import StandardFinalAnswer from "../../standardAgencyAnswer";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import AppCheckbox from "../../../../../../../components/form/appCheckbox";
import useCreateReviewerAnswer from "../useCreateAnswer/useCreateReviewerAnswer";
import AppTextarea from "../../../../../../../components/form/appTextarea";

const ReviewerFinalAnswer = ({ setMenuElement, reviewerAnswerOnPhase2 }) => {
  const { currentStage } = useContext(StageContext);
  const { createReviewerAnswer } = useCreateReviewerAnswer();
  const {
    newReviewerAnswer,
    setNewReviewerAnswer,
    reviewerDegree,
    newAgencyAnswer,
  } = useAnswerContext();
  const [checkedDegree, setCheckedDegree] = useState(null);

  const handleDegreeChange = (degree) => {
    setCheckedDegree(degree === checkedDegree ? "" : degree);

    setNewReviewerAnswer({
      ...newReviewerAnswer,
      checkedReviewerDegree: degree === checkedDegree ? "" : degree,
    });
  };

  useEffect(() => {
    setCheckedDegree(newReviewerAnswer?.checkedReviewerDegree);
  }, []);

  const handleChangeNote = (note) => {
    setNewReviewerAnswer({
      ...newReviewerAnswer,
      reviewerAnswerNote: note,
    });
  };

  const handleChangeDifferentNote = (note) => {
    setNewReviewerAnswer({
      ...newReviewerAnswer,
      differentBetweenOldAndNewExpertAnswerNote: note,
    });
  };

  const handleChangeDifferentAgencyNote = (note) => {
    setNewReviewerAnswer({
      ...newReviewerAnswer,
      differentBetweenAgencyAndExpertAnswerNote: note,
    });
  };

  const renderedDegrees = newReviewerAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={newReviewerAnswer?.checkedReviewerDegree?.id === item?.value}
      onChange={() => handleDegreeChange(item)}
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createReviewerAnswer={createReviewerAnswer}
      label="إجابة المدقق"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[200px]">{renderedDegrees}</div>
        <div className="flex-auto space-y-10">
          {reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.title !==
            newReviewerAnswer?.checkedReviewerDegree?.title && (
            <div className="mt-5 ml-8 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة النظام"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة النظام"
                style={{
                  marginBottom: "24px",
                }}
                value={newReviewerAnswer?.reviewerAnswerNote || ""}
                onChange={(e) => handleChangeNote(e.target.value?.trimStart())}
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
          {reviewerAnswerOnPhase2?.result?.lastAnswer?.reviewerFinalCommitmentLevel?.title !==
            newReviewerAnswer?.checkedReviewerDegree?.title && (
            <div className="mt-5 ml-8 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة التحقق الأولى"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة التحقق الأولى"
                style={{
                  marginBottom: "24px",
                }}
                value={
                  newReviewerAnswer?.differentBetweenOldAndNewExpertAnswerNote ||
                  ""
                }
                onChange={(e) =>
                  handleChangeDifferentNote(e.target.value?.trimStart())
                }
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
          {newReviewerAnswer?.agencyFinalCommitmentLevel?.title !==
            newReviewerAnswer?.checkedReviewerDegree?.title && (
            <div className="mt-5 ml-8 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة الجهة"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة الجهة"
                style={{
                  marginBottom: "24px",
                }}
                value={
                  newReviewerAnswer?.differentBetweenAgencyAndExpertAnswerNote ||
                  ""
                }
                onChange={(e) =>
                  handleChangeDifferentAgencyNote(e.target.value?.trimStart())
                }
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
        </div>
      </div>
    </StandardFinalAnswer>
  );
};

export default ReviewerFinalAnswer;
