import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import Table from "../components/Table";

export function Page16({
  agencyTitle,
  globalStyles,
  agenciesByClassification,
  page = 14,
}) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <Page style={styles.page} author="test">
      <Text style={styles.sectionTitle}>.06 ملحقات</Text>
      <Text style={styles.sectionSecondaryTitle}>
        * قائمة الجهات في المجموعة
      </Text>

      <View
        style={{
          display: "flex",
          flexWrap: "wrap",
          backgroundColor: "#ece8f5",
          border: "solid",
        }}
      >
        {/* <Text
          style={{
            fontSize: 14,
            paddingTop: 8,
            fontWeight: 600,
            textAlign: "right",
            paddingRight: 5,
            backgroundColor: "#fbf9fe",
            color: "#2A2069",
          }}
        >
          الوزارات
        </Text> */}
        <Table data={agenciesByClassification} />
      </View>
      {/* <Text style={{ ...styles.subtitle, marginTop: 30 }}>
        .مرتبة حسب الترتيب الأبجدي* ​{" "}
      </Text> */}

      <View style={styles.bottomInfo}>
        <Text>{agencyTitle}</Text>
        <View style={styles.bottomInfo2}>
          <Text>قياس التحول الرقمي 2024 |</Text>
          <Text style={{ marginLeft: 15, marginRight: 20 }}>{page}</Text>
        </View>
      </View>
    </Page>
  );
}
