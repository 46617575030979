import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useContext, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewTable from "../../../../components/NewTable";
import NewTooltip from "../../../../components/NewTooltip";
import { useDashboardContext } from "../../../../contexts/DashboardContext";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import FlipCard from "../../../../modules/main/home/FlipCard";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { useGetFinishedAnswerAgencies } from "../api/useGetFinishedAnswerAgencies";
import { StageContext } from "../../../../contexts/stageContext";

function AgenciesFinishedAnswering() {
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.font.family = "DiodrumArabic";

  const { currentStage } = useContext(StageContext)
  const phaseThreeStageId = currentStage?.stageIds?.[2];

  const [excelLoading, setExcelLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterValue, setFilterValue] = useState(null);

  const { includedAgenciesCount } = useDashboardContext();

  const downloadExcel = useExcelExport();

  const history = useHistory();

  const surveyId = localStorage.getItem("surveyId");

  const columns = [
    {
      name: "كود الجهة",
      selector: (row) => row.agencyCode,
      header: "agencyCode",
      accessorKey: "agencyCode",
      width: "100px",
    },
    {
      name: "اسم الجهة",
      selector: (row) => row.nameInArabic,
      header: "nameInArabic",
      accessorKey: "nameInArabic",
      cell: (row) => (
        <div className="whitespace-nowrap">{row.nameInArabic}</div>
      ),
    },
    {
      name: "عرض",
      selector: (row) => row.id,
      header: "id",
      accessorKey: "id",
      center: true,
      cell: (row) => (
        <NewTooltip content={"عرض التفاصيل"}>
          <FontAwesomeIcon
            icon={faEye}
            className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
            onClick={() => {
              history.push(`entities/${row.id}/details`);
            }}
          />
        </NewTooltip>
      ),
    },
  ];

  const {
    agenciesFinishedAnswering,
    agenciesFinishedAnsweringCount,
    totalPages,
    isGettingFinishedAnswerAgencies,
  } = useGetFinishedAnswerAgencies({ currentPage, filterValue });

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  // ---------- ChartJS -------------------
  const percentage =
    (agenciesFinishedAnsweringCount / includedAgenciesCount) * 100;

  const data = {
    datasets: [
      {
        label: "جهة",
        data: [percentage, 100 - percentage],
        backgroundColor: ["#1cc182", "#d5ece3"],
        borderColor: ["#1cc182", "#d5ece3"],
        cutout: "70%",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },

      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  // ---------- ChartJS -------------------

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(
      environment.getAgenciesFinishedAnswering,
      {
        surveyId: surveyId,
        KeyWord: filterValue,
        SkipCount: 0,
        MaxResultCount: 500,
        stageId: phaseThreeStageId,
      }
    );

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        agencyCode: ele.agencyCode,
        agencyName: ele.nameInArabic,
      };
    });

    downloadExcel("جهات انتهت من الاٍجابة على المعايير", dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <FlipCard
      title={`جهات انتهت من الاٍجابة على المعايير (${includedAgenciesCount}/${agenciesFinishedAnsweringCount})`}
      frontContent={
        <div className="relative w-full h-[400px] flex justify-center items-center">
          <div className="h-[300px]">
            <Doughnut data={data} options={options} />
          </div>

          <div className="absolute font-semibold text-3xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {`${(
              (agenciesFinishedAnsweringCount / includedAgenciesCount) *
              100
            ).toFixed(2)}%`}
          </div>

          <div className="absolute right-0 top-0 flex flex-col gap-5">
            <div className="flex gap-3 items-center ">
              <div className="w-3 h-3 bg-[#d5ece3]"></div>
              <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                جهات لم تقم باٍنهاء الاجابة
              </p>
            </div>

            <div className="flex gap-3 items-center ">
              <div className="w-3 h-3 bg-[#1cc182]"></div>
              <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                جهات أنهت الاٍجابة
              </p>
            </div>
          </div>
        </div>
      }
      backContent={
        <NewTable
          withSearch
          onSearch={handleFilter}
          withExcel
          excelLoading={excelLoading}
          onExportExcel={handleExcelExport}
          columns={columns}
          data={agenciesFinishedAnswering || []}
          loading={isGettingFinishedAnswerAgencies}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      }
    />
  );
}

export default AgenciesFinishedAnswering;
