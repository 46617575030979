
export const SKIP_TOKEN = 'skip'


type requirementWeightWithSubRequirements = {
    weight: number
    subRequirements: number[]
}

export type IRequirement = number | requirementWeightWithSubRequirements

type requirements = IRequirement[]
export type InterLocutor = {
    requirements: requirements
    weight: number
}
type Perspective = {
    interlocutors: InterLocutor[]
    weight: number
}
export type Survey = {
    perspectives: Perspective[]
}

type requirementResWithSubRequirements = {
    value: 0 | 1 | typeof SKIP_TOKEN
    subRequirements: Array<0 | 1 | typeof SKIP_TOKEN>
}

export type requirementRes = 0 | 1 | typeof SKIP_TOKEN | requirementResWithSubRequirements

type InterLocutorRes = {
    requirements: requirementRes[]
}
export type PerspectiveRes = {
    interlocutors: InterLocutorRes[]
}
export type SurveyRes = {
    perspectives: PerspectiveRes[]
}
export type IndictorRes = {
    surveys: SurveyRes[]
}





const interlocutor: InterLocutor = {
    requirements: [4, 4, 4, 1, 1, 3, 3, 4, 4, 1, 3, 2, 3, 2, 2, 4],
    weight: 30
}

const perspective: Perspective = {
    interlocutors: [
        interlocutor,
        interlocutor,
        {
            requirements: interlocutor.requirements,
            weight: 40
        }
    ],
    weight: 50
}


export const survey: Survey = {
    perspectives: [perspective, perspective],
}






const requirementResValues: requirementRes[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1]
const interlocutorsResValues: InterLocutorRes[] = [
    {requirements: requirementResValues},
    {requirements: requirementResValues},
    {requirements: requirementResValues}
]
const perspectivesResValues: PerspectiveRes[] = [
    {interlocutors: interlocutorsResValues},
    {interlocutors: interlocutorsResValues}
]
const surveyRes: SurveyRes = {
    perspectives: perspectivesResValues
}
export const indictorRes: IndictorRes = {
    surveys: Array.from({length: 2}, () => surveyRes)
}



export const calcPerspectiveResult = (interlocutress: InterLocutor[], interlocutorsAnswers: InterLocutorRes[]) => {
    let perspectiveResult = 0

    for(let i in interlocutress){
        const interlocutorRes = calcInterlocutorResult(interlocutress[i].requirements, interlocutorsAnswers[i].requirements)
        perspectiveResult += calcPercentage(interlocutorRes, interlocutress[i].weight)
    }

    return perspectiveResult
}


// const calcIndictorResult = createCalcIndictorResultFn(survey)
// export const indictorResult = calcIndictorResult(indictorRes)


export function createCalcIndictorResultFn(survey: Survey){
    return function calcIndictorResult(indictorRes: IndictorRes){
        return new Promise<{
            indictorResult: number
            resultsForEachAgency: number[]
        }>((resolve) => {
            let indictorResult = 0
            const calcSurveyResult = createCalcSurveyResultFn(survey)
            
            const resultsForEachAgency = []
            for(let surveyRes of indictorRes.surveys){
                const surveyResult = calcSurveyResult(surveyRes)
                indictorResult += calcSurveyResult(surveyRes) / indictorRes.surveys.length
                resultsForEachAgency.push(surveyResult)
            }

            resolve({indictorResult, resultsForEachAgency})
        })
    }
}


function createCalcSurveyResultFn(survey: Survey){
    return function calcSurveyResult(surveyRes: SurveyRes){
        let surveyResult = 0

        for(let i in survey.perspectives){
            const perspectiveResult = calcPerspectiveResult(survey.perspectives[i].interlocutors, surveyRes.perspectives[i].interlocutors)
            surveyResult += calcPercentage(perspectiveResult, survey.perspectives[i].weight) / 100
        }

        return surveyResult
    }
}


export function calcInterlocutorResult(requirements: requirements, requirementsAns: requirementRes[]){
    const interlocutorCalcFn = createInterlocutorCalcFn(requirements)
    const realResult = interlocutorCalcFn(requirementsAns)
    // Optimal requirements is 1 for all requirements and SKIP_TOKEN for skipped requirements
    const optimalRequirements = requirementsAns.map((val) => {
        if(val === SKIP_TOKEN) return SKIP_TOKEN
        if(val === 0 || val === 1) return 1
        return {
            value: 1,
            subRequirements: val.subRequirements.map((subReq) => subReq === SKIP_TOKEN ? SKIP_TOKEN : 1)
        } satisfies requirementRes
    })

    const optimalResult = interlocutorCalcFn(optimalRequirements)

    // handle case when optimal result is 0 result will be 1 
    if(optimalResult === 0) return 1

    return realResult / optimalResult
}


export function createInterlocutorCalcFn(requirements: requirements){
    return function interlocutorFn(requirementsValues: requirementRes[]){
        return zip(requirements, requirementsValues).reduce((acc, [requirement, requirementRes]) => {
            return acc + (calcResOfRequirement(requirement, requirementRes) ?? 0)
        }, 0)
    }
}



// helpers fns
function calcPercentage(value: number, weight: number){
    return value * weight
}

export function zip<T, U>(a: T[], b: U[]): [T, U][] {
    return a.map((a, i) => [a, b[i]])
}

// Create Test Data fns 
function createInterlocutorRandomWeights(){
    return Array.from({length: 16}, () => randomInt(1, 10))
}


export function createInterlocutor() {
    return {
        requirements: createInterlocutorRandomWeights(),
        weight: randomInt(30, 1000)
    }
}

export function createAgencyAnswerrequirements(){
    return Array.from({length: 16}, () => selectRandom([0, 1, SKIP_TOKEN]))
}


function randomInt(min: number, max: number){
    return Math.floor(Math.random() * (max - min + 1)) + min
}


function selectRandom<T>(arr: T[]){
    return arr[randomInt(0, arr.length - 1)]
}

function sum(arr: number[]){
    return arr.reduce((acc, v) => acc + v, 0)
}

export function calcResOfRequirement(requirement: IRequirement, requirementRes: requirementRes){
    if(requirementRes === SKIP_TOKEN) return 0
    if(requirementRes === 0) return 0

    if(requirementRes === 1) return requirement as number
    if(requirementRes.value === 1 && typeof requirement !== 'number') return requirement.weight as number
    if(requirementRes.value === SKIP_TOKEN && typeof requirement !== 'number') return requirement.weight as number

    assertRequirementHasSubRequirements(requirement)
    
    const filteredSubRequirements = requirementRes.subRequirements.filter((subReq) => subReq !== SKIP_TOKEN)

    const includedSubRequirements = zip(
      requirement.subRequirements,
      requirementRes.subRequirements
    )
      .filter(([_, subReq]) => subReq !== SKIP_TOKEN)
      .map(([subReq, _]) => subReq);

    const sumOfIncludedSubRequirements = sum(includedSubRequirements)

    let value = 0;

    filteredSubRequirements.forEach((subReq, i) => {
        if(subReq === 1) value += requirement.subRequirements[i]
    })

    return (value / sumOfIncludedSubRequirements) * requirement.weight     
}


function assertRequirementHasSubRequirements(requirement: IRequirement): asserts requirement is requirementWeightWithSubRequirements{
    if(typeof requirement === 'number') throw new Error('Requirement should have subRequirements')
}



