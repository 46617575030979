import { Progress } from "flowbite-react";
import { exclamation } from "../../../../../assets/icons/Index";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import TopContent from "./TopContent";
import BottomContent from "./BottomContent";

const theme = {
  base: "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700",
  label: "mb-2 flex justify-between font-medium dark:text-white",
  bar:
    "rounded-full py-1 pb-2 text-center font-medium leading-none text-white dark:text-cyan-100 space-x-2",
  color: {
    dark: "bg-[#575757] dark:bg-gray-300",
    blue: "bg-blue-600",
    red: "bg-[#f05868] dark:bg-red-500",
    green: "bg-[#1cc182]",
    yellow: "bg-[#ffa92b]",
    indigo: "bg-indigo-600 dark:bg-indigo-500",
    purple: "bg-purple-600 dark:bg-purple-500",
    cyan: "bg-cyan-600",
    gray: "bg-gray-500",
    lime: "bg-lime-600",
    pink: "bg-pink-500",
    teal: "bg-teal-600",
  },
  size: {
    sm: "h-1.5",
    md: "h-2.5",
    lg: "h-4",
    xl: "h-7",
  },
};

function SummaryAgencyAnswer({ oldQiyasObj }) {
  // ---------------- ChartJs -------------------
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.font.family = "DiodrumArabic";

  const theme = {
    base: "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700",
    label: "mb-2 flex justify-between font-medium dark:text-white",
    bar:
      "rounded-full py-1 pb-2 text-center font-medium leading-none text-white dark:text-cyan-100 space-x-2",
    color: {
      dark: "bg-gray-600 dark:bg-gray-300",
      blue: "bg-blue-600",
      red: "bg-red-600 dark:bg-red-500",
      green: "bg-green",
      yellow: "bg-yellow-400",
      indigo: "bg-indigo-600 dark:bg-indigo-500",
      purple: "bg-purple-600 dark:bg-purple-500",
      cyan: "bg-cyan-600",
      gray: "bg-gray-500",
      lime: "bg-lime-600",
      pink: "bg-pink-500",
      teal: "bg-teal-600",
    },
    size: {
      sm: "h-1.5",
      md: "h-2.5",
      lg: "h-4",
      xl: "h-7",
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },

      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const data = {
    labels: ["20%", "50%", "80%"],
    datasets: [
      {
        label: "معيار",
        data: [20, 20, 20],

        backgroundColor: ["#f2ebf9", "#f2ebf9", "#f2ebf9"],
        borderColor: ["#f2ebf9", "#f2ebf9", "#f2ebf9"],
        borderWidth: 1,
        circumference: 180,
        rotation: 270,
        offset: 70,
        cutout: "40%",
        borderRadius: 10,
        borderJoinStyle: "round",
        font: {
          weight: "bold",
          family: "DiodrumArabic", // Apply custom font
        },
        padding: "50px",
      },
    ],
  };

  const topData = {
    labels: ["الابداع", "المؤشر الفرعي الثاني", "المؤشر الفرعي الأول"],
    datasets: [
      {
        label: "معيار",
        data: [20, 20, 20],

        backgroundColor: [
          "#373172",
          "#373172",
          "#373172",
          // "#714b96",
          // "#373172",
        ],
        borderColor: ["#48c7e7", "#2db3b4", "#0a7db4"],
        borderWidth: 1,
        circumference: 180,
        rotation: 270,
        offset: 70,
        cutout: "80%",
        borderRadius: 10,
        borderJoinStyle: "round",
      },
    ],
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx } = chart;

      ctx.save();
      ctx.font = "bold 40px DiodrumArabic";

      ctx.fillStyle = "#2a2069";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        `${oldQiyasObj?.theResultOfTheDigitalTransformation || 0}%`,
        chart.getDatasetMeta(0).data[0].x + 10,
        chart.getDatasetMeta(0).data[0].y - 30
      );
      // }
    },
  };
  // ---------------- ChartJs -------------------

  // --------------------------- //
  const buttons = [
    {
      value: 1,
      title: "الاٍبداع",
      bg: "bg-[#006633]",
      bgHover: "bg-[#004d26]",
      rounded: "rounded-s-3xl",
      topContent:
        oldQiyasObj?.equivalentLevelTwentyThree == 1 ? (
          <TopContent
            percentage={oldQiyasObj?.theResultOfTheDigitalTransformation}
            iconColor={"text-[#006633]"}
          />
        ) : null,

      bottomContent:
        oldQiyasObj?.equivalentLevelTwentyTwo == 1 ? (
          <BottomContent
            percentage={oldQiyasObj?.qiyasTwentyTwo}
            iconColor={"text-[#006633]"}
          />
        ) : null,
    },
    {
      value: 2,
      title: "التكامل",
      bg: "bg-[#1cc182]",
      bgHover: "bg-[#0e8b5b]",
      topContent:
        oldQiyasObj?.equivalentLevelTwentyThree == 2 ? (
          <TopContent
            percentage={oldQiyasObj?.theResultOfTheDigitalTransformation}
            iconColor={`text-[#1cc182]`}
          />
        ) : null,

      bottomContent:
        oldQiyasObj?.equivalentLevelTwentyTwo == 2 ? (
          <BottomContent
            percentage={oldQiyasObj?.qiyasTwentyTwo}
            iconColor={`text-[#1cc182]`}
          />
        ) : null,
    },
    {
      value: 3,
      title: "التحسين",
      bg: "bg-[#ff9d00]",
      bgHover: "bg-[#ca7e03]",
      topContent:
        oldQiyasObj?.equivalentLevelTwentyThree == 3 ? (
          <TopContent
            percentage={oldQiyasObj?.theResultOfTheDigitalTransformation}
            iconColor={"text-[#ff9d00]"}
          />
        ) : null,

      bottomContent:
        oldQiyasObj?.equivalentLevelTwentyTwo == 3 ? (
          <BottomContent
            percentage={oldQiyasObj?.qiyasTwentyTwo}
            iconColor={"text-[#ff9d00]"}
          />
        ) : null,
    },
    {
      value: 4,
      title: "الاٍتاحة",
      bg: "bg-[#ff6d00]",
      bgHover: "bg-[#c95802]",
      topContent:
        oldQiyasObj?.equivalentLevelTwentyThree == 4 ? (
          <TopContent
            percentage={oldQiyasObj?.theResultOfTheDigitalTransformation}
            iconColor={"text-[#ff6d00]"}
          />
        ) : null,

      bottomContent:
        oldQiyasObj?.equivalentLevelTwentyTwo == 4 ? (
          <BottomContent
            percentage={oldQiyasObj?.qiyasTwentyTwo}
            iconColor={"text-[#ff6d00]"}
          />
        ) : null,
    },
    {
      value: 5,
      title: "البناء",
      bg: "bg-[#b40000]",
      bgHover: "bg-[#810000]",
      rounded: "rounded-e-3xl",
      topContent:
        oldQiyasObj?.equivalentLevelTwentyThree == 5 ? (
          <TopContent
            percentage={oldQiyasObj?.theResultOfTheDigitalTransformation}
            iconColor={"text-[#b40000]"}
          />
        ) : null,

      bottomContent:
        oldQiyasObj?.equivalentLevelTwentyTwo == 5 ? (
          <BottomContent
            percentage={oldQiyasObj?.qiyasTwentyTwo}
            iconColor={"text-[#b40000]"}
          />
        ) : null,
    },
  ];

  const progressBar = [
    {
      color: "green",
      bgColor: "bg-[#e3f7ef]",
      percentage: oldQiyasObj?.commitmentDoneRate,
      numOfStandards: oldQiyasObj?.commitmentDoneCount,
      commitment: (
        <p className="text-[#2a206a] text-base  ">
          التزام كلي = <span className="font-semibold"> 100 </span>
        </p>
      ),
    },
    {
      color: "yellow",
      bgColor: "bg-[#fff4e5]",
      percentage: oldQiyasObj?.partialCommitmentRate,
      numOfStandards: oldQiyasObj?.partialCommitmentCount,
      commitment: (
        <p className="text-[#2a206a] text-base  ">
          التزام جزئي = <span className="font-semibold"> 50 </span>
        </p>
      ),
    },
    {
      color: "red",
      bgColor: "bg-[#fdeaec]",
      percentage: oldQiyasObj?.commitmentNoRate,
      numOfStandards: oldQiyasObj?.commitmentNoCount,
      commitment: (
        <p className="text-[#2a206a] text-base  ">
          عدم التزام = <span className="font-semibold"> 0 </span>
        </p>
      ),
    },
    {
      color: "dark",
      bgColor: "bg-[#f0efee]",
      percentage: oldQiyasObj?.doNotApplyRate,
      numOfStandards: oldQiyasObj?.doNotApplyCount,
      commitment: <p className="text-[#2a206a] text-base  ">لا ينطبق</p>,
    },
  ];

  return (
    <div className="p-5 m-10">
      <div className="flex flex-col gap-5">
        <h1 className="font-bold text-3xl text-[#26bf83]">ملخص نتيجة الجهة</h1>
        <p className="text-[#2a206a] text-xl ">
          نستعرض أدناه نتيجة التحول الرقمي لجهة {oldQiyasObj?.name} ونتيجة
          الالتزام بالمعايير الأساسية للتحول الرقمي 2023، إضافة إلى نتيجة كل
          مؤشر من المؤشرات الفرعية، وذلك استنادا إلى بياناتهم المدخلة عبر نظام
          القياس.
        </p>
      </div>

      <div className="border-[0.05px] my-5 border-solid border-gray-200 w-full"></div>

      <div className="flex flex-col gap-5 px-5">
        <h1 className="font-bold text-2xl text-[#7c32c9]">
          نتيجة التحول الرقمي
        </h1>

        <div
          className="relative mx-auto"
          style={{ height: 325, width: "700px" }}
        >
          <Doughnut data={data} options={options} plugins={[textCenter]} />
          <div className="w-full absolute left-0 top-0 right-0 bottom-0">
            <Doughnut data={topData} options={options} />
          </div>

          <div className="absolute right-[12px] top-[100px] flex flex-col items-center justify-center rotate-[62.5deg]">
            <svg width={"260"} height={"130"}>
              <path id="curve" d="M 0 120 C 0 120, 130 0, 260 120" />
              <text
                textAnchor="middle"
                style={{
                  fontSize: "20px",
                  fill: "white",
                }}
              >
                <textPath href="#curve" startOffset={"50%"}>
                  المؤشر الفرعي الأول
                </textPath>
              </text>
            </svg>

            <div className="font-semibold text-[#2a2069] text-[22px] -mt-2 rotate-[-63deg]">
              % {oldQiyasObj?.firstIndicator || 0}
            </div>
          </div>

          <div className="absolute flex flex-col items-center justify-center -top-5 left-1/2 -translate-x-1/2">
            <svg width={"260"} height={"130"}>
              <path id="curve" d="M 0 120 C 0 120, 130 0, 260 120" />
              <text
                textAnchor="middle"
                style={{
                  fontSize: "20px",
                  fill: "white",
                }}
              >
                <textPath href="#curve" startOffset={"50%"}>
                  المؤشر الفرعي الثاني
                </textPath>
              </text>
            </svg>

            <div className="font-semibold text-[#2a2069] text-[22px] -mt-2">
              %{oldQiyasObj?.secondIndicator || 0}
            </div>
          </div>

          <div className="absolute left-[12px] top-[100px] flex flex-col items-center justify-center rotate-[-62.5deg]">
            <svg width={"260"} height={"130"}>
              <path id="curve" d="M 0 120 C 0 120, 130 0, 260 120" />
              <text
                textAnchor="middle"
                style={{
                  fontSize: "20px",
                  fill: "white",
                }}
              >
                <textPath href="#curve" startOffset={"50%"}>
                  الاٍبداع
                </textPath>
              </text>
            </svg>

            <div className="font-semibold text-[#2a2069] text-[22px] -mt-2 rotate-[63deg]">
              % {oldQiyasObj?.creativity || 0}
            </div>
          </div>
        </div>
      </div>

      <div className="border-[0.05px] my-5 border-solid border-gray-200 w-full"></div>

      <div className="flex flex-col gap-5 px-5 pb-16">
        <h1 className="font-bold text-2xl text-[#7c32c9]">النتيجة المكافئة</h1>

        <div className="w-full flex justify-center p-10 mt-16 ">
          <div className="inline-flex rounded-2xl shadow-sm" role="group">
            {buttons.map((ele) => (
              <>
                <button
                  type="button"
                  className={`relative px-10 py-2 pb-4 text-xl font-medium text-white ${ele.bg} ${ele.rounded} hover:${ele.bgHover} focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
                >
                  {ele.title}

                  {ele.topContent}
                  {ele.bottomContent}
                </button>
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="border-[0.05px] my-5 border-solid border-gray-200 w-full"></div>

      <div className="flex px-5 gap-16">
        <div className="flex flex-col w-fit">
          <h1 className="font-bold text-2xl whitespace-nowrap text-[#2a2069]">
            نتيجة الالتزام
          </h1>
          <div className="flex flex-1 justify-center items-center">
            <h2 className="font-bold  text-4xl text-[#2a2069]">{`${oldQiyasObj?.theResultOfTheDigitalTransformation?.toFixed(
              2
            ) || 0}%`}</h2>
          </div>
        </div>

        <div className="w-full space-y-2">
          {progressBar.map((ele) => (
            <div className="flex gap-5 p-3 shadow-sm rounded-lg border-2 border-gray-200 w-full">
              <h1 className="font-bold text-xl text-[#2a2069] w-20">
                {ele.percentage?.toFixed(2) || 0}%
              </h1>
              <div className="flex flex-col gap-2 w-full">
                <Progress
                  theme={theme}
                  color={ele.color}
                  progress={ele.percentage?.toFixed(2)}
                  progressLabelPosition="inside"
                  size="xl"
                  labelProgress
                  className={ele.bgColor}
                />
                <div className="flex justify-between px-1.5">
                  <p className="text-[#2a206a] text-base font-semibold ">
                    {ele.numOfStandards || 0} معيار
                  </p>
                  {ele.commitment || 0}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="border-[0.05px] my-5 border-solid border-gray-200 w-full"></div>

      <div className="flex  gap-5 px-5">
        <div className="flex justify-center items-center p-5">
          <img src={exclamation} alt="exclamation" className="w-[100px]" />
        </div>

        <p className="text-[#2a206a] text-lg ">
          <span className="text-[#2a206a] text-lg font-semibold ">
            نتيجة الالتزام
          </span>{" "}
          هي نتيجة مبنية على التزام الجهة في المؤشر الفرعي الأول والمؤشر الفرعي
          الثاني فقط. ويتم حسابها بناء على نسبة متوسط الاٍجابات بحيث يساوي
          الالتزام الكلي 100 درجة والالتزام الجزئي 50 درجة وعدم الالتزام يساوي
          صفر علما بأن النتيجة النهائية المعتمدة في قياس هي{" "}
          <span className="text-[#2a206a] text-lg font-semibold ">
            نتيحة التحول الرقمي
          </span>
        </p>
      </div>
    </div>
  );
}

export default SummaryAgencyAnswer;
