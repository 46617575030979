import { useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { surveyId } from "../../../../helpers/isPermission";
import { useContext } from "react";
import { StageContext } from "../../../../contexts/stageContext";

export const useGetPhase4GetActiveExpertsReviewers = () => {
  const surveyFirstStageId = localStorage.getItem("stageForDashboard");
  const surveyCurrentStageId = localStorage.getItem("surveyStageId");

  const { currentStage } = useContext(StageContext);
  const phase4StageId = currentStage?.stageIds?.[3];

  const { data, isLoading: isGetPhase4GetActiveExpertsReviewers } = useQuery({
    queryKey: ["GetActiveExpertsReviewers"],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetActiveExpertsReviewers,
        {
          SurveyId: surveyId(),
          AgencyFirstStageId: surveyFirstStageId,
          ReviewFirstStageId: surveyCurrentStageId,
          stageId: phase4StageId,
        },
        true,
        signal
      ),
    enabled: surveyFirstStageId != null && surveyCurrentStageId != null,
  });

  const activeExpertsReviewers = data?.result;

  return {
    data,
    activeExpertsReviewers,
    isGetPhase4GetActiveExpertsReviewers,
  };
};
