import { useQuery, useQueryClient } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetFilesNumber = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const { currentStage } = useContext(StageContext)
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const { data, isLoading: isGettingFilesNumber } = useQuery({
    queryKey: ["FilesNumber", currentPage, filterValue, phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getAgenciesFiles,
        {
          KeyWord: filterValue,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          surveyStageId: phaseOneStageId
        },
        true,
        signal
      ),
    enabled: !!phaseOneStageId,
  });

  const filesNumber = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 4);
  const allFilesCount = {
    oldQiyas: data?.result?.allQiyas23FilesCount,
    currentQiyas: data?.result?.allQiyas24FilesCount,
  };

  return {
    queryClient,
    data,
    filesNumber,
    allFilesCount,
    totalCount,
    totalPages,
    isGettingFilesNumber,
  };
};
