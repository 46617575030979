import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Group data into pairs
const groupData = (data) => {
  const grouped = [];
  for (let i = 0; i < data.length; i += 2) {
    grouped.push([data[i], data[i + 1]]);
  }
  return grouped;
};

const Table = ({ data = [] }) => {
  const groupedData = groupData(data);

  return (
    <>
      {groupedData.map((pair, index) => (
        <View
          key={index}
          style={[
            styles.row,
            {
              backgroundColor: index % 2 !== 0 ? "white" : "#ece8f5",
              textAlign: "right",
              paddingRight: 6,
            },
          ]}
        >
          {/* Render each item in the pair */}
          {pair.map(
            (item, itemIndex) =>
              item && ( // Check if item exists (in case of odd number of items)
                <View key={item.id} style={styles.cell}>
                  <Text style={styles.cellText}>{item.nameAr}</Text>
                </View>
              )
          )}
        </View>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  row: {
    flexDirection: "row",
    width: "100%",
    paddingTop: 10,
  },
  cell: {
    width: "50%", // Each cell takes half of the row
  },
  cellText: {
    fontSize: 10,
    color: "#000",
  },
});

export default Table;
