import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router";
import AppCheckbox from "../../../../../../../components/form/appCheckbox";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { axiosGetRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import StandardFinalAnswer from "../../standardAgencyAnswer";
import useCreateReviewerAnswer from "../useCreateAnswer/useCreateReviewerAnswer";

const ReviewerFinalAnswerPhase4ShowingPhase2 = ({
  setMenuElement,
  reviewerAnswerOnPhase2,
}) => {
  const { standardId } = useParams();
  const { currentStage } = useContext(StageContext);
  const { createReviewerAnswer } = useCreateReviewerAnswer();
  const { newReviewerAnswer } = useAnswerContext();

  const { data: degreesResult } = useQuery({
    queryKey: ["getCommitmentLevels", standardId],
    queryFn: () =>
      axiosGetRequest(environment.getCommitmentLevels, {
        standardId: standardId,
      }),
  });

  const checkedPocOfficerDegree = (degreesResult?.result?.filter(
    (item) =>
      item?.id == reviewerAnswerOnPhase2?.result?.reviewerAnswer?.finalCommitmentLevel?.id
  )?.[0]) || null;
  

  const renderedDegrees = newReviewerAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedPocOfficerDegree?.id === item?.id}
      onChange={() => {}}
      disabled
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createReviewerAnswer={createReviewerAnswer}
      label="إجابة المدقق"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[160px]">{renderedDegrees}</div>
      </div>
    </StandardFinalAnswer>
  );
};

export default ReviewerFinalAnswerPhase4ShowingPhase2;
