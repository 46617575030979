import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";

export function Page12({ agencyTitle, globalStyles, perspectives, page = 13 }) {
  const styles = StyleSheet.create({
    ...globalStyles,
    tableHead: {
      backgroundColor: "#2a2069",
      borderRadius: 8,
      textAlign: "right",
      paddingTop: 10,
      paddingRight: 10,
      fontSize: 14,
      color: "#fff",
      marginBottom: 10,
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      borderBottom: "solid",
      borderBottomWidth: 1,
      borderBottomColor: "#eee",
      justifyContent: "flex-end",
      gap: 10,
      paddingVertical: 12,
      marginTop: 8,
      paddingRight: 8,
    },
  });

  return (perspectives?.perspectivesList || [])?.map((perspective, pIndex) => (
    <Page style={styles.page} author="test">
      <Text style={styles.sectionTitle}>.05 النتيجة التفصيلية​</Text>
      <Text style={styles.sectionSecondaryTitle}>
        النتائج التفصيلية – {perspective.title}{" "}
      </Text>
      <Text style={{ ...styles.subtitle }}>
        نستعرض أدناه النتائج التفصيلية للجهة بالمعايير في {perspective.title} :​
      </Text>
      {perspective?.interlocutors?.map((interlocutor) => {
        return (
          <>
            <View style={styles.tableHead}>
              <Text>{interlocutor.title}</Text>
            </View>

            <View
              style={{
                borderStyle: "solid",
                borderRadius: 8,
                borderWidth: 1,
                borderColor: "#ccc",
              }}
            >
              {interlocutor?.standards?.map((standard, index) => (
                <View
                  style={{
                    borderBottomStyle: "solid",
                    borderBottomColor: "#ccc",
                    borderBottomWidth: `${
                      index === interlocutor?.standards?.length - 1 ? 0 : 1
                    }`,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 8,
                      width: "15%",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "90%",
                      }}
                    >
                      <Text>
                        {
                          standard.surveyExpertAnswer?.finalCommitmentLevel
                            .title
                        }{" "}
                      </Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: "#7C32C9",
                        width: "10%",
                        height: "100%",
                        marginLeft: "auto",
                      }}
                    ></View>
                  </View>

                  <View
                    style={{
                      fontSize: 8,
                      width: "85%",
                    }}
                  >
                    <View style={styles.tableRow}>
                      <Text>{standard.title} </Text>
                      <Text>{standard.code} </Text>
                      <Text style={{ color: "#2A2069", fontWeight: 600 }}>
                        المعيار
                      </Text>
                    </View>
                    <View style={styles.tableRow}>
                      <Text>{standard.recommendation} </Text>
                      <Text style={{ color: "#2A2069", fontWeight: 600 }}>
                        التوصية{" "}
                      </Text>
                    </View>
                    <View style={styles.tableRow}>
                      <Text style={{ color: "#2A2069", fontWeight: 600 }}>
                        الأوامر والقرارات والتعاميم المرتبطة​
                      </Text>
                      <Text style={{ textAlign: "right" }}>
                        {standard.expertNote}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>

            <View style={styles.bottomInfo}>
              <Text>{agencyTitle}</Text>
              <View style={styles.bottomInfo2}>
                <Text>قياس التحول الرقمي 2024 |</Text>
                <Text style={{ marginLeft: 15, marginRight: 20 }}>
                  {page + pIndex}
                </Text>
              </View>
            </View>
          </>
        );
      })}
    </Page>
  ));
}
