import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppLoader from "../../../../../../../components/loader";
import { StageContext } from "../../../../../../../contexts/stageContext";
import environment from "../../../../../../../services/environment";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../../../services/Request";
import { useAnswerContext } from "../../answerContext";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";
import StandardAnswerData from "../../standardAnswerData";
import StandardAnswerNotes from "../../standardAnswerNotes";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import Tabs from "../Tabs";
import useCreateReviewerAnswer from "../useCreateAnswer/useCreateReviewerAnswer";
import usePrevReviewerAnswer from "../useGetPrevAnswer/usePrevReviewerAnswer";
import {
  getReOpenRequirementIds,
  getStandardProofs,
  getStandardRequirements,
  hasAtLeastOneReOpenRequirement,
} from "../utils";
import NotesForAgency from "./notesForAgency";
import NotesForAgencyPhase4 from "./notesForAgencyPhase4";
import ReviewerFinalAnswer from "./reviewerFinalAnswer";
import ReviewerFinalAnswerPhase4ShowingPhase2 from "./reviewerFinalAnswerPhase4ShowingPhase2";
import ReviewerNoteTemplate from "./reviewerNoteTemplates";
import ReviewerProofFiles from "./reviewerProofFiles";
import ReviewerRequirementAnswerPhase4 from "./reviewerRequirementAnswerPhase4";
import ReviewerRequirementAnswerPhase4ShowingPhase2 from "./reviewerRequirementAnswerPhase4ShowingPhase2";
import ReviewerVisit from "./reviewerVisit";

function Phase4ReviewerAnswer({ menuElement, setMenuElement }) {

  const { entityId, standardId, programId } = useParams();
  const { currentStage } = useContext(StageContext);

  const { newAgencyAnswer } = useAnswerContext();
  const isForthStage = currentStage?.stageNumber === 4;
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;

  const phase2StageId = currentStage?.stageIds?.[1];

  const { getPrevReviewerAnswer } = usePrevReviewerAnswer();
  const { loadPreReviewerAnswer } = useAnswerContext();
  


  useEffect(() => {
    if (currentStage) {
      getPrevReviewerAnswer(standardId, entityId, currentStage?.id);
    }
  }, [currentStage]);


  const { data: phaseOneAgencyAnswer } = useQuery({
    queryKey: ["prepareStandardForAnswer", standardId, entityId, currentStage?.stageIds?.[0]],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[0],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!isForthStage,
  });

  const { data: phaseThreeAgencyAnswer } = useQuery({
    queryKey: [
      "prepareStandardForAnswer",
      standardId,
      entityId,
      currentStage?.stageIds?.[2],
    ],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[2],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const { data: reviewerAnswerOnPhase2 } = useQuery({
    queryKey: ["getReviewerAnswer", entityId, phase2StageId, standardId],
    queryFn: () =>
      axiosGetRequest(environment.getReviewerAnswer, {
        agencyId: entityId,
        stageId: phase2StageId,
        standardId: standardId,
        POCOfficerId: localStorage.getItem("userId"),
      }),
    enabled: !!phase2StageId,
  });

  const { data: standardRequirementsPhaseThree } = useQuery({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: currentStage?.stageIds?.[2],
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const { createReviewerAnswer } = useCreateReviewerAnswer(reviewerAnswerOnPhase2);

  function createAnswer() {
    createReviewerAnswer(standardId, entityId, currentStage?.id)
  }

  function createAnswerAsDraft() {
    createReviewerAnswer(standardId, entityId, currentStage?.id, true);
  }

  const isNotReopenedStander = !!(
    standardRequirementsPhaseThree?.result?.standardRequirements?.length === 0
  );

  const disableSecondAuditPhase = isNotReopenedStander && !isConfidentialAgency;

  const reOpenRequirementIds = getReOpenRequirementIds(
    standardRequirementsPhaseThree
  );

  const phaseOneStanderProofs = getStandardProofs(phaseOneAgencyAnswer);
  const phaseThreeStanderProofs = getStandardProofs(
    phaseThreeAgencyAnswer
  ).filter((item) =>
    hasAtLeastOneReOpenRequirement(item, reOpenRequirementIds)
  );

  const phaseOneAgencyAnswerRequirements = getStandardRequirements(phaseOneAgencyAnswer) ?? []
  const phaseThreeAgencyAnswerRequirements = getStandardRequirements(phaseThreeAgencyAnswer) ?? []

  if (menuElement === 0) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreReviewerAnswer}
        />
      </div>
    );
  }

  if (loadPreReviewerAnswer && menuElement !== 0) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      </div>
    );
  }

  if (menuElement === 1) {
    return (
      <Tabs.TabList key={1}>
        <Tabs.Tab title="فتح النظام">
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ReviewerProofFiles
              setMenuElement={setMenuElement}
              standardProofs={phaseOneStanderProofs ?? []}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab title="إعادة فتح النظام" omit={disableSecondAuditPhase}>
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ReviewerProofFiles
              setMenuElement={setMenuElement}
              standardProofs={phaseThreeStanderProofs ?? []}
            />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 3) {
    return (
      <Tabs.TabList key={2}>
        <Tabs.Tab title="فتح النظام">
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <StandardAnswerRequirement setMenuElement={setMenuElement}>
              <ReadOnlyRequirements
                requirements={phaseOneAgencyAnswerRequirements}
              />
            </StandardAnswerRequirement>
          </div>
        </Tabs.Tab>
        <Tabs.Tab title="التحقق  الأولى">
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ReviewerRequirementAnswerPhase4ShowingPhase2
              setMenuElement={setMenuElement}
              reviewerAnswerOnPhase2={reviewerAnswerOnPhase2}
              phaseOneAgencyAnswer={phaseOneAgencyAnswer}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab title="إعادة فتح النظام" omit={disableSecondAuditPhase}>
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <StandardAnswerRequirement setMenuElement={setMenuElement}>
              <ReadOnlyRequirements
                requirements={phaseThreeAgencyAnswerRequirements.filter(
                  (item) =>
                    reOpenRequirementIds.includes(item.standardRequirementId)
                )}
              />
            </StandardAnswerRequirement>
          </div>
        </Tabs.Tab>
        <Tabs.Tab title="التحقق النهائي" omit={disableSecondAuditPhase}>
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ReviewerRequirementAnswerPhase4 setMenuElement={setMenuElement} />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 4) {
    return (
      <Tabs.TabList key={3}>
        <Tabs.Tab title="التحقق  الأولى">
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ReviewerFinalAnswerPhase4ShowingPhase2
              setMenuElement={setMenuElement}
              reviewerAnswerOnPhase2={reviewerAnswerOnPhase2}
              phaseOneAgencyAnswer={phaseOneAgencyAnswer}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab title="التحقق النهائي" omit={disableSecondAuditPhase}>
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ReviewerFinalAnswer
              setMenuElement={setMenuElement}
              reviewerAnswerOnPhase2={reviewerAnswerOnPhase2}
            />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }



  if (menuElement === 5) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <ReviewerVisit setMenuElement={setMenuElement} />
      </div>
    );
  }


  if (menuElement === 6) {
    return (
      <Tabs.TabList key={3}>
        <Tabs.Tab title="التحقق  الأولى">
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <NotesForAgencyPhase4
              setMenuElement={setMenuElement}
              reviewerAnswerOnPhase2={reviewerAnswerOnPhase2}
              phaseOneAgencyAnswer={phaseOneAgencyAnswer}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab title="التحقق النهائي" omit={disableSecondAuditPhase}>
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <NotesForAgency setMenuElement={setMenuElement} />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 7) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <StandardAnswerNotes
          createAnswer={createAnswer}
          createAnswerAsDraft={createAnswerAsDraft}
          disableSave={disableSecondAuditPhase}
        >
          <ReviewerNoteTemplate />
        </StandardAnswerNotes>
      </div>
    );
  }
}

export default Phase4ReviewerAnswer;
