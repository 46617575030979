import { useState } from "react";
import DashboardTabs from "../../components/DashboardTabs";
import { AuditorExpertContextProvider } from "../../contexts/AuditorExpertDashboardContext";
import { isExpertManager } from "../../helpers/isPermission";
import AdminDashboardStatsPhase2 from "../admin/phase2/AdminDashboardStatsPhase2";
import AuditorExpertDashboardCards from "./AuditorExpertDashboardCards";
import AuditorExpertDashboardStats from "./AuditorExpertDashboardStatsPhase2";
import AuditorExpertDashboardStats4 from "./AuditorExpertDashboardStatsPhase4";
import AuditorExpertDashboardCardsPhase4 from "./AuditorExpertDashboardCardsPhase4";
import AdminDashboardStatsPhase4 from "../admin/phase4/AdminDashboardStatsPhase4";

function AuditorExpertDashboard() {

  const [activeTab, setActiveTab] = useState(0);

  const dashboardPhases = [
    {
      title: "التحقق  الأولى",
      content: <>
        <AuditorExpertDashboardStats />,
        <AuditorExpertDashboardCards />
      </>
    },
    {
      title: "التحقق  الثانية",
      content: <>
        <AuditorExpertDashboardStats4 />,
        <AuditorExpertDashboardCardsPhase4 />
      </>
    },
  ];

  const dashboardPhasesExpertManger = [
    {
      title: "التحقق  الأولى",
      content: <>
        <AdminDashboardStatsPhase2 />,
        <AuditorExpertDashboardCards />
      </>
    },
    {
      title: "التحقق  الثانية",
      content: <>
        <AdminDashboardStatsPhase4 />,
        <AuditorExpertDashboardCardsPhase4 />
      </>
    },
  ];

  return (
    <>
    <AuditorExpertContextProvider>
      {isExpertManager ? (
        <DashboardTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={dashboardPhasesExpertManger}
        />
      ) : (
        <DashboardTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={dashboardPhases}
        />
      )}
      </AuditorExpertContextProvider>
      </>
  );
}

export default AuditorExpertDashboard;
