import AnswerDegreeItem from "./answerDegreeItem";
import { useAnswerContext } from "../answerContext";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../../services/Request";
import environment from "../../../../../../services/environment";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router";
import { StageContext } from "../../../../../../contexts/stageContext";

const PocOfficerAnswerDegrees = () => {
  const { newPocOfficerAnswer, pocOfficerDegree } = useAnswerContext();
  const { entityId, standardId, programId } = useParams();
  const { newAgencyAnswer } = useAnswerContext();
  const { currentStage } = useContext(StageContext);
  const isForthStage = currentStage?.stageNumber === 4;
  const phase2StageId = currentStage?.stageIds?.[1];
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;

  const { data: phaseOneAgencyAnswer } = useQuery({
    queryKey: ["prepareStandardForAnswer", standardId, entityId],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[0],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!isForthStage,
  });

  const { data: pocOfficerAnswerOnPhase2 } = useQuery({
    queryKey: ["getPOCOfficerAnswer", entityId, phase2StageId, standardId],
    queryFn: () =>
      axiosGetRequest(environment.getPOCOfficerAnswer, {
        agencyId: entityId,
        stageId: phase2StageId,
        standardId: standardId,
        expertId: localStorage.getItem("userId"),
      }),
    enabled: !!phase2StageId,
  });

  const { data: standardRequirementsPhaseThree } = useQuery({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: currentStage?.stageIds?.[2],
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const isNotReopenedStander = !!(
    standardRequirementsPhaseThree?.result?.standardRequirements?.length === 0
  );

  const disableSecondAuditPhase = isNotReopenedStander && !isConfidentialAgency;

  if (isForthStage && !disableSecondAuditPhase) {
    return (
      <div className="bg-[#F9F9FF] p-3 mr-3 rounded-b-[12px] mb-3">
        <div className="bg-white p-3 px-5 flex flex-wrap">
          <AnswerDegreeItem
            degree={
              phaseOneAgencyAnswer?.result?.surveyAgencyAnswer
                ?.finalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              phaseOneAgencyAnswer?.result?.surveyAgencyAnswer
                ?.finalCommitmentLevel?.color || "#fff"
            }
            title="إجابة الجهة للمرحلة الأولى"
          />
          <AnswerDegreeItem
            degree={
              pocOfficerAnswerOnPhase2?.result?.lastAnswer
                ?.pocOfficerFinalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              pocOfficerAnswerOnPhase2?.result?.lastAnswer
                ?.pocOfficerFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
          />
          <AnswerDegreeItem
            degree={
              pocOfficerAnswerOnPhase2?.result?.lastAnswer?.expertFinalCommitmentLevel?.title
               || "لا يوجد"
            }
            circleColor={
              pocOfficerAnswerOnPhase2?.result?.lastAnswer?.expertFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة الخبير لمرحلة التحقق  الأولى"
          />
          <AnswerDegreeItem
            degree={
              pocOfficerAnswerOnPhase2?.result?.lastAnswer
                ?.reviewerFinalCommitmentLevel?.title || "لا يوجد"
            }
            circleColor={
              pocOfficerAnswerOnPhase2?.result?.lastAnswer
                ?.reviewerFinalCommitmentLevel?.color || "#fff"
            }
            title="إجابة المدقق لمرحلة التحقق  الأولى"
          />
        </div>
        <div className="bg-[#F9F9FF] rounded-b-[12px] mb-3">
          <div className="bg-white p-3 px-5 flex flex-wrap">
            <AnswerDegreeItem
              degree={
                newPocOfficerAnswer?.agencyFinalCommitmentLevel?.title ||
                "لا يوجد"
              }
              circleColor={
                newPocOfficerAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
              }
              title="إجابة الجهة  لمرحلة إعادة فتح النظام"
            />
            
            <AnswerDegreeItem
              degree={
                newPocOfficerAnswer?.checkedPocOfficerDegree?.title || "لا يوجد"
              }
              circleColor={
                newPocOfficerAnswer?.checkedPocOfficerDegree?.color || "#fff"
              }
              title="إجابة ضابط الاتصال لمرحلة التحقق  النهائي"
            />
            <AnswerDegreeItem
              degree={
                newPocOfficerAnswer?.reviewerFinalCommitmentLevel?.title ||
                "لا يوجد"
              }
              circleColor={
                newPocOfficerAnswer?.reviewerFinalCommitmentLevel?.color ||
                "#fff"
              }
              title="إجابة المدقق لمرحلة التحقق  النهائي"
            />
            <AnswerDegreeItem
              degree={
                newPocOfficerAnswer?.expertFinalCommitmentLevel?.title ||
                "لا يوجد"
              }
              circleColor={
                newPocOfficerAnswer?.expertFinalCommitmentLevel?.color || "#fff"
              }
              title="إجابة الخبير لمرحلة التحقق  النهائي"
            />

            <AnswerDegreeItem
              degree={
                pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel
                  ?.title || "لا يوجد"
              }
              circleColor={
                pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel
                  ?.color || "#fff"
              }
              title="الدرجة المقترحة من النظام"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
        <AnswerDegreeItem
          degree={
            newPocOfficerAnswer?.agencyFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newPocOfficerAnswer?.agencyFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الجهة للمرحلة الأولى"
        />
        <AnswerDegreeItem
          degree={
            newPocOfficerAnswer?.reviewerFinalCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            newPocOfficerAnswer?.reviewerFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة المدقق لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newPocOfficerAnswer?.expertFinalCommitmentLevel?.title || "لا يوجد"
          }
          circleColor={
            newPocOfficerAnswer?.expertFinalCommitmentLevel?.color || "#fff"
          }
          title="إجابة الخبير لمرحلة التحقق  الأولى"
        />

        <AnswerDegreeItem
          degree={
            newPocOfficerAnswer?.checkedPocOfficerDegree?.title || "لا يوجد"
          }
          circleColor={
            newPocOfficerAnswer?.checkedPocOfficerDegree?.color || "#fff"
          }
          title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.title ||
            "لا يوجد"
          }
          circleColor={
            pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.color ||
            "#fff"
          }
          title="الدرجة المقترحة من النظام"
        />
      </div>
    </div>
  );
};

export default PocOfficerAnswerDegrees;
