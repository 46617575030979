import { errorToast, successToast } from "../../../../../../../helpers/toast";
import { validateAnswerNote } from "../../../../../../../helpers/utils";
import { axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import usePrevReviewerAnswer from "../useGetPrevAnswer/usePrevReviewerAnswer";
import { showRequirement } from "../utils";

const useCreateReviewerAnswer = (reviewerAnswerOnPhase2) => {
  const {
    newReviewerAnswer,
    newAgencyAnswer,
    reviewerDegree,
    setCreateAnswerLoading,
    reviewerAnswer,
  } = useAnswerContext();
  const { getPrevReviewerAnswer } = usePrevReviewerAnswer();

  const createReviewerAnswer = async (standardId, entityId, currentStage) => {
    const reviewerRequirementAnswers =
      newReviewerAnswer?.standardRequirements?.map((req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            note: req?.subReq || "",
            donotApply: subReq?.donotApply,
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          note: req?.note || "",
          donotApply: req?.donotApply,
          reviewerSubRequirementAnswers: subStandardRequirements,
        };
      });

    // * Proof Files
    // const agencyProofAnswers = newReviewerAnswer?.standardProofs?.map(
    //   (file) => {
    //     return {
    //       standardProofId: file?.standardProofId,
    //       note: file?.note,
    //     };
    //   }
    // );

    // const data = {
    //   surveyStageId: Number(currentStage),
    //   standardId: Number(standardId),
    //   agencyId: Number(entityId),
    //   reviewerId: Number(localStorage.getItem("userId")),
    //   reviewerRequirementAnswers,
    //   // agencyProofAnswers,
    //   noteForAgency: {
    //     content: "",
    //   },
    //   selfEvaluationCommitmentLevelId:
    //     reviewerDegree?.selfEvaluationCommitmentLevel?.id,
    //   finalCommitmentLevelId: newReviewerAnswer?.checkedreviewerDegree?.id,
    //   selfEvaluationDegree: reviewerDegree?.selfEvaluationDegree,
    //   finalDegree: newReviewerAnswer?.checkedreviewerDegree?.fixedDegree,
    //   donotApply: false,
    //   visitId: 0,
    // };

    const surveyReviewerAnswerStandardProofs =
      newReviewerAnswer?.standardProofs
        ?.filter((proof) => proof?.reviewerProofNotes)
        .map((proof) => ({
          standardProofId: proof?.standardProofId || null,
          reviewerProofNotes: proof?.reviewerProofNotes || "",
        })) || [];

    // validation
    if (newReviewerAnswer?.checkedReviewerDegree?.value == null) {
      return errorToast("لابد من وجود اجابة للتدقيق");
    }

    const data = {
      standardId: Number(standardId),
      surveyStageId: Number(currentStage),
      reviewerRequirementAnswers,
      surveyReviewerAnswerStandardProofs,
      noteForAgency: {
        unCheckRequirement: !newReviewerAnswer?.newRequirementsAgency
          ? ""
          : `<ul style="padding : 6px 0 ">` +
            newReviewerAnswer?.newRequirementsAgency +
            "</ul>",
        template: "",
        content: newReviewerAnswer?.reviewerSelectedTemplate,
        noteTemplateId: newReviewerAnswer?.selectedTemplatesForAgency,
      },
      reviewerId: Number(localStorage.getItem("userId")),
      agencyId: Number(entityId),
      finalDegree: newReviewerAnswer?.checkedReviewerDegree?.fixedDegree,
      finalCommitmentLevelId: newReviewerAnswer?.checkedReviewerDegree?.value,
      selfEvaluationCommitmentLevelId:
        reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.id || null,
      selfEvaluationDegree: reviewerDegree?.reviewerSelfEvaluationDegree || 0,

      reviewerAnswerNote:
        reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.title !==
        newReviewerAnswer?.checkedReviewerDegree?.title
          ? newReviewerAnswer?.reviewerAnswerNote
          : null,
      differentBetweenOldAndNewExpertAnswerNote:
        reviewerAnswer?.reviewerFinalCommitmentLevel?.title !==
        newReviewerAnswer?.checkedExpertDegree?.title
          ? newReviewerAnswer?.differentBetweenOldAndNewExpertAnswerNote
          : null,

      differentBetweenAgencyAndExpertAnswerNote:
        newAgencyAnswer?.checkedAgencyDegree?.title !==
        newReviewerAnswer?.checkedExpertDegree?.title
          ? newReviewerAnswer?.differentBetweenAgencyAndExpertAnswerNote
          : null,

      needVisit: newReviewerAnswer?.needVisit,
      visitNote: newReviewerAnswer?.visitNote,
    };

    if (
      reviewerDegree?.reviewerSelfEvaluationCommitmentLevel?.title !==
        newReviewerAnswer?.checkedReviewerDegree?.title &&
      !newReviewerAnswer?.reviewerAnswerNote
    ) {
      errorToast(
        "الرجاء كتابة سبب تغيير اجابتك عن إجابة الدرجة المقترحة من النظام فى إجابة المدقق"
      );
      return;
    }

    if (
      reviewerAnswerOnPhase2?.result?.lastAnswer?.reviewerFinalCommitmentLevel
        ?.title !== newReviewerAnswer?.checkedReviewerDegree?.title &&
      !newReviewerAnswer?.differentBetweenOldAndNewExpertAnswerNote
    ) {
      errorToast("الرجاء كتابة سبب تغيير اجابتك عن إجابة التحقق الأولى");
      return;
    }

    if (
      newReviewerAnswer?.agencyFinalCommitmentLevel?.title !==
        newReviewerAnswer?.checkedReviewerDegree?.title &&
      !newReviewerAnswer?.differentBetweenAgencyAndExpertAnswerNote
    ) {
      errorToast("الرجاء كتابة سبب تغيير اجابتك عن إجابة الجهة");
      return;
    }

    if (validateAnswerNote(data?.reviewerAnswerNote)) {
      errorToast("سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز");
      return;
    }

    if (validateAnswerNote(data?.differentBetweenOldAndNewExpertAnswerNote)) {
      errorToast("سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز");
      return;
    }

    if (validateAnswerNote(data?.differentBetweenAgencyAndExpertAnswerNote)) {
      errorToast("سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز");
      return;
    }

    const hasRequiredRequirements =
      newReviewerAnswer?.standardRequirements?.filter((requirement) =>
        showRequirement(requirement)
      )?.length > 0;

    if (
      hasRequiredRequirements &&
      !newReviewerAnswer?.selectedTemplatesForAgency
    ) {
      errorToast("الرجاء اختيار قالب الملاحظات للجهة");
      return;
    }

    // if (
    //   hasRequiredRequirements &&
    //   !newReviewerAnswer?.reviewerSelectedTemplate
    // ) {
    //   errorToast("الرجاء كتاية ملاحظات التدقيق للجهة");
    //   return;
    // }

    setCreateAnswerLoading(true);
    const res = await axiosPostRequest(environment.addReviewerAnswer, data);
    if (res?.success) {
      successToast("تم اضافة الاجاية بنجاح");

      getPrevReviewerAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage)
      );
    }
    setCreateAnswerLoading(false);
  };
  return { createReviewerAnswer };
};

export default useCreateReviewerAnswer;
