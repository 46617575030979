import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router";
import AppCheckbox from "../../../../../../../components/form/appCheckbox";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { axiosGetRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import StandardFinalAnswer from "../../standardAgencyAnswer";

const ExpertManagerFinalAnswerPhase4ShowingPhase2 = ({ setMenuElement, expertMangerAnswerOnPhase2 }) => {
  const { standardId } = useParams();

  const { data: degreesResult } =
  useQuery({
    queryKey: ["getCommitmentLevels", standardId],
    queryFn: () =>
      axiosGetRequest(environment.getCommitmentLevels, {
        standardId: standardId,
      }),
  });

  const { currentStage } = useContext(StageContext);

  const {
    newExpertManagerAnswer,
  } = useAnswerContext();


  const checkedExpertMangerDegree = (degreesResult?.result?.filter(
    (item) =>
      item?.id == expertMangerAnswerOnPhase2?.result?.expertManagerAnswer?.finalCommitmentLevel?.id
  )?.[0]) || null;

  const renderedDegrees = newExpertManagerAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedExpertMangerDegree?.id === item?.id}
      disabled
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createReviewerAnswer={() =>{}}
      label="إجابة رئيس الخبراء"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[160px]">{renderedDegrees}</div>
      </div>
    </StandardFinalAnswer>
  );
};

export default ExpertManagerFinalAnswerPhase4ShowingPhase2;
