import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import Slide10 from "../assets/Slide10.jpg";

export function Page10({ agencyTitle, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <Page size="A4" style={styles.page}>
      <Image src={Slide10} />
      <View style={styles.bottomInfo}>
        <Text>{agencyTitle}</Text>
        <View style={styles.bottomInfo2}>
          <Text>قياس التحول الرقمي 2024 |</Text>
          <Text style={{ marginLeft: 15, marginRight: 20 }}>11</Text>
        </View>
      </View>
    </Page>
  );
}
