import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { ProgressBar } from "../components/ProgressBar";

export function Page5({ perspectives, agencyTitle, qiyasObj, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
    perspectivesContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    perspectivesNameAndWeightContainer: {
      fontSize: 8,
      textAlign: "center",
      borderRadius: 5,
      border: "1px solid #7C32C9",
    },
    perspectivesNameContainer: {
      backgroundColor: "#7C32C9",
      color: "white",
      height: 70,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
    },
    perspectivesWeight: {
      color: "#7C32C9",
      padding: 5,
      paddingTop: 15,
    },
    interluctorsContainer: {
      width: "100%",
      fontSize: 8,
      textAlign: "center",
      marginTop: 2,
    },
    interlocutor: {
      width: "100%",
      border: "1px solid #7C32C9",
      borderRadius: 5,
      padding: 5,
      marginTop: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    perspectivesLabelContainer: {
      fontSize: 8,
      textAlign: "center",
    },
    perspectivesLabel: {
      height: 70,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    perspectivesWeightLabel: {
      padding: 5,
      paddingTop: 15,
    },
    interluctorsLabelContainer: {
      width: "100%",
      fontSize: 8,
      textAlign: "center",
      marginTop: 2,
    },
    interluctorsLabel: {
      width: "100%",
      height: 200,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  });

  return (
    <>
      <Page style={styles.page} author="test">
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Text style={styles.sectionSecondaryTitle}>
            آلية احتساب نتيجة قياس 2024
          </Text>
          <Text style={{ ...styles.sectionSecondaryTitle, marginLeft: 5 }}>
            .
          </Text>
          <Text style={styles.sectionSecondaryTitle}>02</Text>
        </View>
        <Text style={styles.sectionTitle}>
          آلية احتساب نتيجة الجهة في قياس التحول الرقمي 4202 وفق ما يلي:
        </Text>
        <Text style={styles.subtitle}>
          تم احتساب نتيجة الجهة بناءً على التزامها في (
          {perspectives?.perspectivesList?.length}) مناظير و (
          {perspectives?.interlocutorsCount}) محوراً.​
        </Text>
        <View style={styles.perspectivesContainer}>
          {perspectives?.perspectivesList?.map((item) => (
            <View
              style={{
                width: `${92 / perspectives?.perspectivesList?.length}%`,
              }}
            >
              <View style={styles.perspectivesNameAndWeightContainer}>
                <View style={styles.perspectivesNameContainer}>
                  <Text>{item.title}</Text>
                </View>
                <Text style={styles.perspectivesWeight}>{item.weight}</Text>
              </View>
              <View style={styles.interluctorsContainer}>
                {item.interlocutors.map((interlocutor) => (
                  <View
                    style={{
                      ...styles.interlocutor,
                      height: (100 / item.interlocutors.length) * 2.5,
                    }}
                  >
                    <Text>{interlocutor.title}</Text>
                  </View>
                ))}
              </View>
            </View>
          ))}
          <View
            style={{ width: `${50 / perspectives?.perspectivesList?.length}%` }}
          >
            <View style={styles.perspectivesLabelContainer}>
              <View style={styles.perspectivesLabel}>
                <Text>المنظور</Text>
              </View>
              <Text style={styles.perspectivesWeightLabel}>الوزن</Text>
            </View>
            <View style={styles.interluctorsLabelContainer}>
              <View style={styles.interluctorsLabel}>
                <Text>المحاور</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.bottomInfo}>
          <Text>{agencyTitle}</Text>
          <View style={styles.bottomInfo2}>
            <Text>قياس التحول الرقمي 2024 |</Text>
            <Text style={{ marginLeft: 15, marginRight: 20 }}>5</Text>
          </View>
        </View>
      </Page>
      <Page style={styles.page} author="test">
        <Text style={styles.sectionSecondaryTitle}>معادلة احتساب النتيجة</Text>

        <View style={styles.progressHeaderContainer}>
          <Text style={styles.progressHeaderLabel}>المنظور</Text>
          <Text style={styles.progressHeaderWeightLabel}>وزن المنظور​</Text>
        </View>
        {perspectives?.perspectivesList?.map((item, index) => (
          <ProgressBar
            key={index}
            label={item.title}
            percentage={item.resut?.toFixed(2)}
            weight={item.weight}
            color="#7C32C9"
          />
        ))}

        <View style={styles.totalScore}>
          <Text style={styles.totalScoreLabel}>
            : نتيجة التحول الرقمي للجهة
          </Text>
          <View style={styles.totalScoreTextWrapper}>
            <Text style={styles.totalScoreText}>
              {qiyasObj?.finalResult?.toFixed(2)} %
            </Text>
          </View>
        </View>
        <View style={styles.bottomInfo}>
          <Text>{agencyTitle}</Text>
          <View style={styles.bottomInfo2}>
            <Text>قياس التحول الرقمي 2024 |</Text>
            <Text style={{ marginLeft: 15, marginRight: 20 }}>6</Text>
          </View>
        </View>
      </Page>
    </>
  );
}
