import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { surveyId } from "../../../../helpers/isPermission";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetPhase4Standards = ({ currentPage, filterValue }) => {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const queryClient = useQueryClient();

  const { currentStage } = useContext(StageContext)
  const phase4StageId = currentStage?.stageIds?.[3];

  const { data, isLoading: isGettingAllStandards } = useQuery({
    queryKey: ["Phase2AllStandards", currentPage, filterValue],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetStandardStatusBasedOnReviewOperation,
        {
          KeyWord: filterValue,
          StageId: surveyStageId,
          SurveyId: surveyId(),
          SkipCount: currentPage * 5,
          MaxResultCount: 5,
          stageId: phase4StageId
        },
        true,
        signal
      ),
    enabled: surveyStageId != null,
  });

  const allStandards = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  return {
    queryClient,
    allStandards,
    totalCount,
    totalPages,
    data,
    isGettingAllStandards,
  };
};
