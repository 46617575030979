import React from "react";
import answer from "./../../../../../../../assets/icons/answer.svg";
import NextBtn from "../../components/buttons/nextBtn";
import { showRequirement } from "../utils";

const NotesForAgencyPhase4 = ({ setMenuElement, expertAnswerOnPhase2, phaseOneAgencyAnswer}) => {

  const reviewerNoteTemplate = expertAnswerOnPhase2?.result?.lastAnswer?.reviewerNoteForAgency?.noteTemplate?.templateContent || ""
  const reviewerRequirementAnswers =  expertAnswerOnPhase2?.result?.lastAnswer.reviewerRequirementAnswers ?? []
  const reviewerNotContent = expertAnswerOnPhase2?.result?.lastAnswer?.reviewerNoteForAgency?.content ?? ""

  const pocOfficerNoteTemplate = expertAnswerOnPhase2?.result?.lastAnswer?.pocOfficerNoteForAgency?.noteTemplate?.templateContent || ""
  const pocOfficerRequirementAnswers =  expertAnswerOnPhase2?.result?.lastAnswer.pocOfficerRequirementAnswers ?? []
  const pocOfficerNotContent = expertAnswerOnPhase2?.result?.lastAnswer?.pocOfficerNoteForAgency?.content ?? ""  

  const expertNoteTemplate =  expertAnswerOnPhase2?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate?.templateContent || ""
  const expertRequirementsAnswer = phaseOneAgencyAnswer?.standardRequirements?.map(
    (requirement) => {
      const reqAnswer =
      expertAnswerOnPhase2?.result?.surveyExpertAnswer?.expertRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.expertSubRequirementAnswers) {
        const { expertSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = expertSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...subReq,
              ...sub,
              id: subReq.id,
              decision: sub?.decision,
              donotApply: sub?.donotApply,
            };
          }
        );

        return {
          ...requirement,
          ...reqs,
          decision: reqs?.decision,
          donotApply: reqs?.donotApply,
          id: requirement.id,
          recommendation: "",
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements =
        requirement?.subStandardRequirements?.map((item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        }));
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    }
  ) ?? [];
  const expertNotContent = expertAnswerOnPhase2?.result?.lastAnswer?.expertNoteForAgency?.content ?? ""

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={answer}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">الملاحظات</h1>
          </div>
        </div>

        <div className="w-[100%]  pr-[88px] flex flex-col  items-start overflow-y-auto h-[300px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات المدقق:
            </h2>

            <div
              dangerouslySetInnerHTML={{
                __html: reviewerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {reviewerRequirementAnswers?.map((requirement, index) => (
                <li>
                  {showRequirement(requirement)
                    ? requirement.standardRequirementTitle
                    : ""}
                  <ul>
                    {requirement.reviewerSubRequirementAnswers?.map(
                      (subRequirement, i) => (
                        <>
                          {(subRequirement.decision == 0 ||
                            !subRequirement.decision) &&
                          subRequirement?.donotApply == false ? (
                            <li className="mx-10">
                              {subRequirement.standardRequirementTitle}
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: reviewerNotContent || "",
              }}
            ></div>
            {!reviewerNoteTemplate &&
              !reviewerNotContent &&
              !reviewerRequirementAnswers.length && <div>لا يوجد ملاحظات</div>}
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الخبير:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: expertNoteTemplate || "",
              }}
            ></div>    
              <ul className="px-2">
                {expertRequirementsAnswer?.map((requirement, index) => (
                  <li>
                    {showRequirement(requirement) ? requirement.title : ""}
                    <ul>
                      {requirement.subStandardRequirements.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">{subRequirement.title}</li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: expertNotContent || "",
              }}
            ></div>
            {!expertRequirementsAnswer.length &&
              !expertNoteTemplate &&
              !expertNotContent && <div>لا يوجد ملاحظات</div>}
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات ضابط الاتصال:
            </h2>

            <div
              dangerouslySetInnerHTML={{
                __html: pocOfficerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {pocOfficerRequirementAnswers?.map((requirement, index) => (
                <li>
                  {showRequirement(requirement)
                    ? requirement.standardRequirementTitle
                    : ""}
                  <ul>
                    {requirement.pocOfficerSubRequirementAnswers?.map(
                      (subRequirement, i) => (
                        <>
                          {(subRequirement.decision == 0 ||
                            !subRequirement.decision) &&
                          subRequirement?.donotApply == false ? (
                            <li className="mx-10">
                              {subRequirement.standardRequirementTitle}
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: pocOfficerNotContent || "",
              }}
            ></div>
            {!pocOfficerNoteTemplate &&
              !pocOfficerNotContent &&
              !pocOfficerRequirementAnswers.length && (
                <div>لا يوجد ملاحظات</div>
              )}
          </div>
        </div>
      </div>
      <NextBtn onClick={() => setMenuElement(7)} />
    </>
  );
};

export default NotesForAgencyPhase4;
