import { StyleSheet, Text, View } from "@react-pdf/renderer";

export const ScaleItem = ({ style, children }) => {
  const styles = StyleSheet.create({
    scaleItem: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "8px",
    },
    scaleItemText: {
      color: "white",
      fontSize: 10,
      fontWeight: "bold",
    },
  });

  return (
    <View style={[styles.scaleItem, style]}>
      <Text style={styles.scaleItemText}>{children}</Text>
    </View>
  );
};
