import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetExperts = ({ currentPage, filterValue }) => {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const queryClient = useQueryClient();

  const { currentStage } = useContext(StageContext)
  const phase2StageId = currentStage?.stageIds?.[1];

  const { data, isLoading: isGettingAllExperts } = useQuery({
    queryKey: ["Experts", currentPage, filterValue],
    queryFn: () =>
      axiosGetRequest(environment.GetAllExpertsWithAssignedStandards, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        POCOfficerId: pocOfficerId,
        ExpertId: expertId,
        KeyWord: filterValue,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
        stageId: phase2StageId,
      }),
    enabled: surveyStageId != null,
  });

  const allExperts = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  return {
    queryClient,
    allExperts,
    totalCount,
    totalPages,
    data,
    isGettingAllExperts,
  };
};
