import classNames from "classnames";
import React from "react";

const UploadItemProgress = ({ progress, status }) => {
  const progressClasses = classNames(
    {
      "bg-emerald-400": status === "completed",
      "bg-red-500": status === "error",
    },
    "flex flex-col justify-center rounded-full overflow-hidden bg-teal-500 text-xs text-white text-center whitespace-nowrap transition duration-500 "
  );

  return (
    <div
      className="flex w-full h-2  bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div className={progressClasses} style={{ width: progress + "%" }}></div>
    </div>
  );
};

export default UploadItemProgress;
