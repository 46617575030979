import { useEffect, useState, useTransition } from "react";
import Skeleton from "react-skeleton-loader";
import { InputLabel, NewECard, Title } from "../../../components";
import { useHistory, useParams } from "react-router-dom";
import { programsIcon } from "../../../assets/icons/Index";
import NewHeader from "../../../layouts/header/NewHeader";
import {
  formatDate,
  formatTime,
  permissionsCheck,
} from "../../../helpers/utils";
import DashboardStats from "../../../components/dashboard/DashboardStats";
import {
  documentsFileCheckmark,
  documentsFileExcel,
  documentsFileWarningError,
  documentsFilesCopy,
} from "../../../assets/icons/Index";
import DrawerFilter from "../../../components/DrawerFilter";
import { useTranslation } from "react-i18next";
import TableCardSwitch from "../../../components/TableCardSwitch";
import NewTable from "../../../components/NewTable";
import { Badge } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import AppButton from "../../../components/buttons/appButton";
import { get, put } from "../../../services/Request";
import environment from "../../../services/environment";
import AppPagination from "../../../components/Pagination";

const EditRequests = () => {
  const history = useHistory();
  const { programId } = useParams();
  const { t } = useTranslation();

  const [isPending, startTransition] = useTransition();

  const [standardCode, setStandardCode] = useState("");
  const [expertName, setExpertName] = useState("");
  const [loading] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [editRequests, setEditRequests] = useState([]);
  const [editRequestsStatistics, setEditRequestsStatistics] = useState({
    all: 0,
    inProgress: 0,
    approved: 0,
    rejected: 0,
  });
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [dateInputs, setDateInputs] = useState({
    from: "",
    to: "",
  });
  const [isApproved, setIsApproved] = useState(undefined);
  const [isReviewed, setIsReviewed] = useState(null);

  const infoList = [
    {
      id: 1,
      title: "الكل",
      number: editRequestsStatistics.all,
      icon: documentsFilesCopy,
      color: "#DBD9FF",
    },
    {
      id: 2,
      title: "قيد المراجعة",
      number: editRequestsStatistics.inProgress,
      icon: documentsFileExcel,
      color: "#FFF0DA",
    },
    {
      id: 3,
      title: "تمت الموافقة",
      number: editRequestsStatistics.approved,
      icon: documentsFileCheckmark,
      color: "#D6FFEF",
    },
    {
      id: 4,
      title: "تم الرفض",
      number: editRequestsStatistics.rejected,
      icon: documentsFileWarningError,
      color: "#FFE4E4",
    },
  ];

  const getEditRequests = (page = currentPage + 1) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.GetExpertManagerReAnswerRequest +
        `?pageSize=10&pageNumber=${page}${
          standardCode && `&standardCode=${standardCode}`
        }${expertName && `&expertUserName=${expertName}`}${
          dateInputs.from && `&startDate=${dateInputs.from}`
        }${dateInputs.to && `&endDate=${dateInputs.to}`}${
          isReviewed ? `&isReviewed=${isReviewed}` : ""
        }${isApproved !== undefined ? `&isApproved=${isApproved}` : ""}`,
      config,
      (res) => {
        if (res.status === 200) {
          setEditRequests(res.data?.result);
          setTotalPages(res.data?.result.totalCount / 10);
        }
      }
    );
  };

  const getEditRequestsStatistics = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.GetExpertManagerReAnswerRequestStatistics,
      config,
      (res) => {
        if (res.status === 200) {
          setEditRequestsStatistics(res.data?.result);
        }
      }
    );
  };

  const updateEditRequest = (isApproved, surveyExpertReAnswerRequestIds) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    put(
      environment.UpdateExpertManagerReAnswerRequestApproved,
      {
        isApproved,
        surveyExpertReAnswerRequestIds,
      },
      config,
      (res) => {
        if (res.status === 200) {
          getEditRequests();
          getEditRequestsStatistics();
        }
      }
    );
  };

  useEffect(() => {
    getEditRequests();
    getEditRequestsStatistics();
  }, []);

  const handleRowSelection = (row) => {
    if (selectedRows.includes(row.id)) {
      setSelectedRows(selectedRows.filter((id) => id !== row.id));
    } else {
      setSelectedRows([...selectedRows, row.id]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedRows(editRequests?.items?.map((row) => row.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowClick = (row) => {
    history.push(`/programs/${programId}/edit-requests/${row.id}`);
  };

  const standardColumn = [
    {
      name: (
        <div className="flex justify-center items-center">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
          />
        </div>
      ),
      accessorKey: "select",
      selector: (row) => row.select,
      center: true,
      cell: (row) => (
        <div className="flex justify-center items-center">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selectedRows.includes(row.id)} // Mark as checked if selected
            onChange={() => handleRowSelection(row)}
          />
        </div>
      ),
    },
    {
      name: "رقم الطلب",
      accessorKey: "id",
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => <div className="text-sm text-[#292069]">{row.id}</div>,
    },
    {
      name: "رقم المعيار",
      accessorKey: "code",
      selector: (row) => row.standard.code,
      sortable: true,
      cell: (row) => (
        <div className="text-sm text-[#292069]">{row.standard.code}</div>
      ),
    },
    // {
    //   name: "أسم المعيار",
    //   accessorKey: "titleArabic",
    //   selector: (row) => row.standard.titleArabic,
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="text-sm text-[#292069]">{row.standard.titleArabic}</div>
    //   ),
    // },
    {
      name: "مقدم الطلب",
      accessorKey: "name",
      selector: (row) => row.expert.name,
      center: true,
      cell: (row) => (
        <div className="text-sm text-[#292069]">{row.expert.name}</div>
      ),
      sortable: true,
    },
    {
      name: "الوقت والتاريخ",
      accessorKey: "dateTime",
      selector: (row) => row.dateTime,
      center: true,
      cell: (row) => (
        <div className="text-sm text-[#292069]">
          {formatDate(new Date(row.date)) +
            " / " +
            formatTime(new Date(row.date))}
        </div>
      ),
      sortable: true,
    },
    {
      name: "سبب التعديل",
      accessorKey: "status",
      selector: (row) => row.notes,
      center: true,
      cell: (row) => <div className="text-sm text-[#292069]">{row.notes}</div>,
      sortable: true,
    },
    {
      name: "حالة الطلب",
      accessorKey: "status",
      selector: (row) => row.isApproved,
      center: true,
      cell: (row) => (
        <Badge
          className={`font-medium pb-3 px-5 rounded-xl ${
            row.isApproved
              ? "bg-[#D6FFEF] text-[#109763]"
              : row.isApproved === null
              ? "bg-[#FFF0DA] text-[#FF9A05]"
              : "bg-[#FFE4E4] text-[#FF5353]"
          }`}
          size="sm"
        >
          {row.isApproved
            ? "تمت الموافقة"
            : row.isApproved === null
            ? "قيد المراجعة"
            : "تم الرفض"}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: "إجراءات",
      selector: "actions",
      sortable: true,
      center: true,
      cell: (row) => (
        <div
          className="flex justify-around items-center w-40"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="flex justify-center items-center rounded-full w-8 h-8 bg-[#EEF7FF] text-[#108AE0] cursor-pointer"
            onClick={(e) =>
              row.isApproved === null && updateEditRequest(true, [row.id])
            }
          >
            <FontAwesomeIcon icon={faThumbsUp} size="1x" />
          </div>
          <div
            className="flex justify-center items-center rounded-full w-8 h-8 bg-red-100 text-red-500 cursor-pointer"
            onClick={(e) =>
              row.isApproved === null && updateEditRequest(false, [row.id])
            }
          >
            <FontAwesomeIcon icon={faTimesCircle} size="1x" />
          </div>
        </div>
      ),
      omit: permissionsCheck("Pages.UserType.Admin"),
    },
  ];

  const handleChangeDate = (e) => {
    setDateInputs({ ...dateInputs, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    getEditRequests();
  };

  const clearSearch = () => {
    startTransition(() => {
      setStandardCode("");
      setExpertName("");
      setDateInputs({
        from: "",
        to: "",
      });
    });
  };

  useEffect(() => {
    if (isPending === false) {
      getEditRequests();
    }
  }, [isPending]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getEditRequests(newOffset + 1);
  };

  const handleStatisticsCardClick = (cardId) => {
    startTransition(() => {
      setIsReviewed(null);
      if (cardId === 1) {
        setIsApproved(undefined);
      }
      if (cardId === 2) {
        setIsReviewed(true);
        setIsApproved(undefined);
      }
      if (cardId === 3) {
        setIsApproved(true);
      }
      if (cardId === 4) {
        setIsApproved(false);
      }
    });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <div className="px-5">
        <Title
          subTitle="طلبات التعديل"
          iconTitle={programsIcon}
          subTitleSeconed={"طلبات التعديل"}
          seconed={permissionsCheck("Pages.UserType.Admin") ? false : true}
          thired={permissionsCheck("Pages.UserType.Admin") ? true : false}
          title="قياس ٢٠٢٤"
          withoutButton={true}
          checked={false}
          withSwitch={false}
          toPageSeconed={() => history.push("/programs")}
          toPage={() => history.push("/programs")}
        />
      </div>
      <DashboardStats
        lists={infoList}
        loading={false}
        onClick={handleStatisticsCardClick}
      />
      <div className="flex justify-end m-5 py-5 items-center">
        <DrawerFilter handleSearch={handleSearch} handleClear={clearSearch}>
          <div className="w-full">
            <InputLabel
              type={"text"}
              name="title"
              value={standardCode}
              onChange={(e) => setStandardCode(e.target.value)}
              label={"رقم المعيار"}
              isRequired={false}
            />
            <InputLabel
              type={"text"}
              name="title"
              value={expertName}
              onChange={(e) => setExpertName(e.target.value)}
              label={"مقدم الطلب"}
              isRequired={false}
            />
            <div className="w-full flex gap-3">
              <div className="flex flex-1 flex-col space-y-1 ">
                <label className="label_input text-lg text-[#292069] ">
                  أو اختر الفترة من:
                </label>

                <input
                  style={{ border: "2px solid #a8b2d2" }}
                  onChange={handleChangeDate}
                  value={dateInputs.from}
                  type="date"
                  name="from"
                  className="text-[14px] text-[#7D8BAB] h-[42px] border-none rounded-3xl py-3 px-5"
                  placeholder="22-10-2023"
                />
              </div>

              <div className="flex flex-1 flex-col space-y-1 ">
                <label className="label_input text-lg text-[#292069] ">
                  اٍلي:
                </label>

                <input
                  style={{ border: "2px solid #a8b2d2" }}
                  onChange={handleChangeDate}
                  value={dateInputs.to}
                  type="date"
                  name="to"
                  className="text-[14px] text-[#7D8BAB] h-[42px] border-none rounded-3xl py-3 px-5"
                  placeholder="22-10-2023"
                />
              </div>
            </div>
          </div>
        </DrawerFilter>
      </div>
      <div className="m-5 py-5">
        {editRequestsStatistics.all === 0 ? (
          <div className="flex justify-center items-center">
            <span className="text-lg text-[#2B2969]">{t("لا يوجد طلبات")}</span>
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-between items-center">
              <h3 className="my-8 mx-1">
                {loading ? (
                  <Skeleton />
                ) : (
                  `${editRequests?.items?.length} عنصر بالقائمة`
                )}
              </h3>
              <div className="flex items-center justify-between gap-4">
                {permissionsCheck("Pages.UserType.ExpertManager") && (
                  <>
                    <AppButton
                      onClick={() =>
                        updateEditRequest(
                          true,
                          !!selectedRows.length
                            ? selectedRows
                            : editRequests?.items?.map((row) => row.id)
                        )
                      }
                    >
                      موافقة على الكل
                    </AppButton>
                    <AppButton
                      className="bg-transparent !text-blue border border-blue hover:!text-white"
                      onClick={() =>
                        updateEditRequest(
                          false,
                          !!selectedRows.length
                            ? selectedRows
                            : editRequests?.items?.map((row) => row.id)
                        )
                      }
                    >
                      رفض الكل
                    </AppButton>
                  </>
                )}
                {/* <TableCardSwitch
                  showTable={showTable}
                  setShowTable={setShowTable}
                /> */}
              </div>
            </div>
            {!showTable ? (
              <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                {editRequests?.items?.map((standard, index) => (
                  <NewECard
                    isReturnedStandard={false}
                    openModalAssign={() => {}}
                    items={[
                      {
                        title: "مقدم الطلب",
                        detail: standard.reqPerson,
                      },
                      {
                        title: "رقم المعيار",
                        detail: standard.code,
                      },
                    ]}
                    from="editRequests"
                    answered={standard.status}
                    showButtonAnswered={true}
                    title={standard.title}
                    handleAssignReviewerAndExpertAndPOCOfficer={() =>
                      history.push(`/`)
                    }
                    showMore={() => {}}
                  />
                ))}
              </div>
            ) : (
              <div className="bg-white">
                <NewTable
                  data={editRequests?.items || []}
                  columns={standardColumn}
                  onSelectedRowsChange={() => {}}
                  handleRowClick={handleRowClick}
                  pagination={false}
                />
                <AppPagination
                  loading={loading}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageClick={handlePageClick}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EditRequests;
