import { useEffect, useState } from "react";
import { axiosGetRequest } from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import TableSkeleton from "../../../../../components/table/TableSkeleton";
import SummaryAgencyAnswer from "./SummaryAgencyAnswer";
import useAxiosGetRequest from "../../../../../helpers/customHooks/useAxiosGetRequest";
import NewTable from "../../../../../components/NewTable";
import DownloadButton from "../../../../../components/DownloadButton";
import { DropdownMultiSelect } from "../../../../../components";
import useExcelExport from "../../../../../helpers/customHooks/useExcelExport";
import { isAgency } from "../../../../../helpers/isPermission";
import { useQuery } from "@tanstack/react-query";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DigitalTransformationReport } from "../../../agencyAnswerReport/DigitalTransformationReport";

const CurrentSurveys = () => {
  const surveyId = localStorage.getItem("surveyId");
  const agencyId = localStorage.getItem("agencyId");

  const [selectedAgency, setSelectedAgency] = useState("");

  const [getCurrentQiyas, currentQiyas, getOldQiyasLoading] =
    useAxiosGetRequest();

  const { data: perspectivesResponse, isLoading: isGettingPerspectives } =
    useQuery({
      queryKey: ["GetAgencyPerspectives", surveyId, agencyId, selectedAgency],
      queryFn: () =>
        axiosGetRequest(environment.GetAgencyPerspectives, {
          agencyId: selectedAgency?.value || agencyId,
          surveyId,
        }),
      enabled: !!(selectedAgency?.value && agencyId && surveyId),
    });

  const {
    data: perspectivesWithAllDetailsResponse,
    isLoading: isGettingWithAllDetailsPerspectives,
  } = useQuery({
    queryKey: [
      "GetAgencyPerspectivesWithAllDetails",
      surveyId,
      agencyId,
      selectedAgency,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAgencyPerspectivesWithAllDetails, {
        agencyId: selectedAgency?.value || agencyId,
        surveyId,
      }),
    enabled: !!(selectedAgency?.value && agencyId && surveyId),
  });

  const {
    data: agencyStandardsCountResponse,
    isLoading: isGettingAgencyStandardsCount,
  } = useQuery({
    queryKey: [
      "GetAgencyStandardsCountByCommitmentLevel",
      surveyId,
      agencyId,
      selectedAgency,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAgencyStandardsCountByCommitmentLevel, {
        agencyId: selectedAgency?.value || agencyId,
        surveyId,
      }),
    enabled: !!(selectedAgency?.value && agencyId && surveyId),
  });

  const { data: agencyResultsResponse, isLoading: isGettingAgencyResults } =
    useQuery({
      queryKey: ["GetAgencyResults", agencyId, selectedAgency],
      queryFn: () =>
        axiosGetRequest(environment.GetAgencyResults, {
          agencyId: selectedAgency?.value || agencyId,
        }),
      enabled: !!(selectedAgency?.value && agencyId),
    });

  const {
    data: agenciesByClassificationResponse,
    isLoading: isGettingAgenciesByClassification,
  } = useQuery({
    queryKey: ["GetAgenciesByClassification", agencyId, selectedAgency],
    queryFn: () =>
      axiosGetRequest(environment.GetAgenciesByClassification, {
        agencyId: selectedAgency?.value || agencyId,
      }),
    enabled: !!(selectedAgency?.value && agencyId),
  });

  const { data: agencyStandardsResponse, isLoading: isGettingAgencyStandards } =
    useQuery({
      queryKey: ["GetAgencyStandards", surveyId, agencyId, selectedAgency],
      queryFn: () =>
        axiosGetRequest(environment.GetAgencyStandards, {
          agencyId: selectedAgency?.value || agencyId,
          surveyId,
        }),
      enabled: !!(selectedAgency?.value && agencyId && surveyId),
    });

  const [filteredPerspectives, setFilteredPerspectives] = useState(
    perspectivesResponse?.result || []
  );
  const [filteredInterlocutors, setFilteredInterlocutors] = useState(
    currentQiyas?.result?.interlocutors || []
  );

  const [filteredStandards, setFilteredStandards] = useState(
    agencyStandardsResponse?.result || []
  );

  useEffect(() => {
    setFilteredPerspectives(perspectivesResponse?.result);
  }, [perspectivesResponse]);

  useEffect(() => {
    setFilteredInterlocutors(currentQiyas?.result?.interlocutors);
  }, [currentQiyas]);

  useEffect(() => {
    setFilteredStandards(agencyStandardsResponse?.result);
  }, [agencyStandardsResponse]);

  const getStatistics = async (id) => {
    if (id || agencyId != "null")
      getCurrentQiyas(environment.GetAgencyStatistics, {
        agencyId: id ? id : agencyId,
        surveyId,
      });
  };

  const getAllAgencies = async () => {
    const resp = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );
    setSelectedAgency({
      label: resp?.result?.[0].nameInArabic,
      value: resp?.result?.[0].id,
    });
  };

  useEffect(() => {
    getAllAgencies();
  }, []);

  const [allAgenciesOptions, setAllAgenciesOptions] = useState([]);

  const handleChangeAgency = (selectedAgencyObj) => {
    setSelectedAgency(selectedAgencyObj || "");
  };

  const handleGetAllAgencies = async () => {
    const data = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );

    const agenciesOptions = data?.result?.map((item) => ({
      label: item.nameInArabic || "",
      value: item.id,
      name: item.name || "",
    }));

    setAllAgenciesOptions(agenciesOptions);
    setSelectedAgency(agenciesOptions[0]);
  };

  useEffect(() => {
    handleGetAllAgencies();
  }, []);

  // ------------------ Agencies --------------

  useEffect(() => {
    if (agencyId == "null") getStatistics(selectedAgency?.value);
    else {
      getStatistics(agencyId);
    }
  }, [selectedAgency?.value]);

  const qiyasObj = currentQiyas?.result;

  const perspectivesDataForExcel = qiyasObj?.perspectives?.map((ele) => {
    return {
      المنظور: ele.title,
      "مستوى الالتزام": ele.resultTypeName,
      النتيجة: ele.result,
      "النتيجة السابقة": ele.previousResult,
    };
  });

  const interlocutorsDataForExcel = qiyasObj?.interlocutors?.map((ele) => {
    return {
      المحور: ele.title,
      "مستوى الالتزام": ele.resultTypeName,
      النتيجة: ele.result,
      "النتيجة السابقة": ele.previousResult,
    };
  });

  const standardsDataForExcel = agencyStandardsResponse?.result?.map((ele) => {
    return {
      الكود: ele.code,
      "التزام الجهة": ele.answerTypeAgencyName,
      "التزام الخبير": ele.answerTypeExpertName,
    };
  });

  const perspectivesColumns = [
    {
      name: "المنظور",
      selector: (row) => row.title,
      header: "المنظور",
      accessorKey: "title",
      center: true,
    },
    {
      name: "مستوى الالتزام",
      selector: (row) => row.resultTypeName,
      header: "مستوى الالتزام",
      accessorKey: "resultTypeName",
      center: true,
    },
    {
      name: "النتيجة",
      selector: (row) => row.result,
      header: "النتيجة",
      accessorKey: "result",
      center: true,
      cell: (row) => row.result.toFixed(2),
    },
    {
      name: "النتيجة السابقة",
      selector: (row) => row.previousResult,
      header: "النتيجة السابقة",
      accessorKey: "previousResult",
      center: true,
      cell: (row) => row.previousResult.toFixed(2),
    },
  ];

  const interlocutorsColumns = [
    {
      name: "المحور",
      selector: (row) => row.title,
      header: "المحور",
      accessorKey: "title",
      center: true,
    },
    {
      name: "مستوى الالتزام",
      selector: (row) => row.resultTypeName,
      header: "مستوى الالتزام",
      accessorKey: "resultTypeName",
      center: true,
    },
    {
      name: "النتيجة",
      selector: (row) => row.result,
      header: "النتيجة",
      accessorKey: "result",
      center: true,
      cell: (row) => row.result.toFixed(2),
    },
    {
      name: "النتيجة السابقة",
      selector: (row) => row.previousResult,
      header: "النتيجة السابقة",
      accessorKey: "previousResult",
      center: true,
      cell: (row) => row.previousResult.toFixed(2),
    },
  ];

  const standardsColumns = [
    {
      name: "المعيار",
      selector: (row) => row.code,
      header: "المعيار",
      accessorKey: "code",
      center: true,
    },
    {
      name: "التزام الجهة",
      selector: (row) => row.answerTypeAgencyName,
      header: "التزام الجهة",
      accessorKey: "answerTypeAgencyName",
      center: true,
    },
    {
      name: "التزام الخبير",
      selector: (row) => row.answerTypeExpertName,
      header: "التزام الخبير",
      accessorKey: "answerTypeExpertName",
      center: true,
    },
  ];

  // New Table //
  const downloadExcel = useExcelExport();

  // search
  const handleFilterPerspectives = (value) => {
    if (!value) {
      setFilteredPerspectives(perspectivesResponse?.result);
    } else {
      const filtered = perspectivesResponse?.result?.filter((ele) => {
        const { result, resultTypeName } = ele;
        return result.includes(value) || resultTypeName.includes(value);
      });
      setFilteredPerspectives(filtered);
    }
  };

  const handleFilterInterlocutors = (value) => {
    if (!value) {
      setFilteredInterlocutors(currentQiyas?.result?.interlocutors);
    } else {
      const filtered = currentQiyas?.result?.interlocutors?.filter((ele) => {
        const { result, resultTypeName } = ele;
        return result.includes(value) || resultTypeName.includes(value);
      });
      setFilteredInterlocutors(filtered);
    }
  };

  const handleFilterStandards = (value) => {
    if (!value) {
      setFilteredStandards(agencyStandardsResponse?.result);
    } else {
      const filtered = agencyStandardsResponse?.result?.filter((ele) => {
        const { answerTypeAgencyName, answerTypeExpertName, code } = ele;
        return (
          answerTypeAgencyName.includes(value) ||
          answerTypeExpertName.includes(value) ||
          code.includes(value)
        );
      });
      setFilteredStandards(filtered);
    }
  };

  return (
    <>
      {getOldQiyasLoading ? (
        <TableSkeleton />
      ) : (
        <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="flex-1">
              {!isAgency && (
                <DropdownMultiSelect
                  placeholder={"اختر جهة حكومية"}
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedAgency}
                  handleChange={handleChangeAgency}
                  options={allAgenciesOptions || []}
                  isMultiple={false}
                  isRequired={false}
                  label={"اختر جهة حكومية"}
                />
              )}
            </div>
            <div className="flex-1 flex justify-end gap-3 self-end">
              <PDFDownloadLink
                document={
                  <DigitalTransformationReport
                    agencyTitle={selectedAgency?.label}
                    qiyasObj={qiyasObj}
                    agencyResults={agencyResultsResponse?.result}
                    agenciesByClassification={
                      agenciesByClassificationResponse?.result
                    }
                    perspectivesWithAllDetails={
                      perspectivesWithAllDetailsResponse?.result
                    }
                  />
                }
                fileName={`التقرير النهائي لجهة ${selectedAgency?.label} 2024.pdf`}
              >
                {({ loading }) => (
                  <DownloadButton loading={loading}>
                    تحميل التقرير النهائي (ملف PDf)
                  </DownloadButton>
                )}
              </PDFDownloadLink>
            </div>
          </div>

          <div>
            <SummaryAgencyAnswer
              qiyasObj={qiyasObj}
              agencyStandardsCount={agencyStandardsCountResponse?.result}
            />
            <NewTable
              withSearch={true}
              withExcel={true}
              onExportExcel={() =>
                downloadExcel(
                  `تقرير المناظير القياس الحالي لجهة ${selectedAgency?.label}`,
                  perspectivesDataForExcel
                )
              }
              onSearch={handleFilterPerspectives}
              loading={isGettingPerspectives}
              columns={perspectivesColumns}
              data={filteredPerspectives}
              pagination={false}
              fixedHeader={true}
            />
            <div className="my-10"></div>
            <NewTable
              withSearch={true}
              withExcel={true}
              onExportExcel={() =>
                downloadExcel(
                  `تقرير المحاور القياس الحالي لجهة ${selectedAgency?.label}`,
                  interlocutorsDataForExcel
                )
              }
              onSearch={handleFilterInterlocutors}
              loading={getOldQiyasLoading}
              columns={interlocutorsColumns}
              data={filteredInterlocutors}
              pagination={false}
              fixedHeader={true}
            />
            <div className="my-10"></div>
            <NewTable
              withSearch={true}
              withExcel={true}
              onExportExcel={() =>
                downloadExcel(
                  `تقرير المعايير القياس الحالي لجهة ${selectedAgency?.label}`,
                  standardsDataForExcel
                )
              }
              onSearch={handleFilterStandards}
              loading={isGettingAgencyStandards}
              columns={standardsColumns}
              data={filteredStandards}
              pagination={false}
              fixedHeader={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CurrentSurveys;
