import { useState } from "react";
import ReadOnlyRequirements from "../../components/readOnlyRequirements";
import StandardAnswerRequirement from "../../standardAnswerRequirements";
import MainRequirement from "../../standardAnswerRequirements/ui/mainRequirement";
import SubRequirement from "../../standardAnswerRequirements/ui/subRequirement";

const ReviewerRequirementAnswerPhase4ShowingPhase2 = ({
  setMenuElement,
  reviewerAnswerOnPhase2,
  phaseOneAgencyAnswer,
}) => {
  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState("reviewer");

  const agencyAnswer = phaseOneAgencyAnswer?.result;

  
  const pocOfficerRequirementsAnswer = agencyAnswer?.standardRequirements?.map(
    (requirement) => {
      const reqAnswer =
        reviewerAnswerOnPhase2.result?.lastAnswer?.pocOfficerRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.pocOfficerSubRequirementAnswers) {
        const { pocOfficerSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = pocOfficerSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...sub,
              title: subReq?.title,
              id: subReq.id,
            };
          }
        );

        return {
          ...reqs,
          title: requirement?.title,
          id: requirement.id,
          recommendation: "",
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    }
  );
  const expertRequirementsAnswer = agencyAnswer?.standardRequirements?.map(
    (requirement) => {
      const reqAnswer =
        reviewerAnswerOnPhase2.result?.lastAnswer?.expertRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.expertSubRequirementAnswers) {
        const { expertSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = expertSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...sub,
              title: subReq?.title,
              id: subReq.id,
            };
          }
        );

        return {
          ...reqs,
          title: requirement?.title,
          id: requirement.id,
          recommendation: "",
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    }
  );

  const reviewerRequirementsAnswer = agencyAnswer?.standardRequirements?.map(
    (requirement) => {
      const reqAnswer =
        reviewerAnswerOnPhase2?.result?.reviewerAnswer?.reviewerRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.reviewerSubRequirementAnswers) {
        const { reviewerSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = reviewerSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...subReq,
              ...sub,
              id: subReq.id,
            };
          }
        );

        return {
          ...requirement,
          ...reqs,
          id: requirement.id,
          recommendation: "",
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    }
  );

  const renderedReviewerRequirements =
    reviewerRequirementsAnswer?.length > 0 &&
    reviewerRequirementsAnswer?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {}}
            handleDonotApplyRequirementChange={() => {}}
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <SubRequirement
                      key={subRequirement?.id}
                      title={subRequirement?.title}
                      checked={subRequirement?.decision}
                      onChange={() => {}}
                      subRequirementDonotApplyChecked={
                        subRequirement?.donotApply
                      }
                      handleDonotApplySubRequirementChange={() => {}}
                    />
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex  py-5 w-full">
          <p
            className={
              selectedRequirementsTab == "reviewer"
                ? "bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("reviewer")}
          >
            المدقق
          </p>

          <p
            className={
              selectedRequirementsTab == "expert"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("expert")}
          >
            الخبير
          </p>
          <p
            className={
              selectedRequirementsTab == "pocOfficer"
                ? "bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer"
                : "bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer"
            }
            onClick={() => setSelectedRequirementsTab("pocOfficer")}
          >
            ضابط الاتصال
          </p>
        </div>

        {selectedRequirementsTab === "reviewer" && (
          <>{renderedReviewerRequirements}</>
        )}

        {selectedRequirementsTab === "expert" && (
          <ReadOnlyRequirements requirements={expertRequirementsAnswer} />
        )}
        {selectedRequirementsTab === "pocOfficer" && (
          <ReadOnlyRequirements requirements={pocOfficerRequirementsAnswer} />
        )}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ReviewerRequirementAnswerPhase4ShowingPhase2;
