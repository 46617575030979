import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";

const SCALE_ITEMS = [
  { label: "التزام كلّي", style: "scaleItemSecond", color: "#1DC183" },
  { label: "التزام جزئي", style: "scaleItemThird", color: "#FF9D00" },
  { label: "عدم التزام", style: "scaleItemFirst", color: "#B40002" },
  { label: "لا ينطبق", style: "scaleItemFourth", color: "#555" },
];

export function Page11({ agencyTitle, globalStyles, perspectives }) {
  const styles = StyleSheet.create({
    ...globalStyles,
    perspectivesContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    perspectivesNameAndWeightContainer: {
      fontSize: 8,
      textAlign: "center",
      borderRadius: 5,
      border: "1px solid #7C32C9",
    },
    perspectivesNameContainer: {
      backgroundColor: "#7C32C9",
      color: "white",
      height: 70,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
    },
    perspectivesWeight: {
      color: "#7C32C9",
      padding: 5,
      paddingTop: 15,
    },
    interluctorsContainer: {
      width: "100%",
      fontSize: 8,
      textAlign: "center",
      marginTop: 2,
    },
    interlocutor: {
      width: "100%",
      border: "1px solid #7C32C9",
      borderRadius: 5,
      padding: 5,
      marginTop: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  });

  return (
    <Page style={styles.page} author="test">
      <Text style={styles.sectionTitle}>05. النتيجة التفصيلية</Text>
      <Text style={styles.sectionSecondaryTitle}> النتائج التفصيلية​</Text>

      <Text style={styles.subtitle}>
        :نستعرض أدناه مدى التزام الجهة بجميع المعايير
      </Text>

      <View style={styles.perspectivesContainer}>
        {perspectives?.perspectivesList?.map((item) => (
          <View
            style={{
              width: `${92 / perspectives?.perspectivesList?.length}%`,
            }}
          >
            <View style={styles.perspectivesNameAndWeightContainer}>
              <View style={styles.perspectivesNameContainer}>
                <Text>{item.title}</Text>
              </View>
              <Text style={styles.perspectivesWeight}>{item.weight}</Text>
            </View>
            <View style={styles.interluctorsContainer}>
              {item.interlocutors.map((interlocutor) => (
                <View
                  style={{
                    ...styles.interlocutor,
                    height: (100 / item.interlocutors.length) * 5,
                  }}
                >
                  <Text>{interlocutor.title}</Text>
                  <View style={styles.interluctorsContainer}>
                    {interlocutor.standards.map((standard) => (
                      <View
                        style={{
                          backgroundColor: `${standard.surveyExpertAnswer?.finalCommitmentLevel.color}`,
                          borderRadius: 8,
                          marginBottom: 2,
                        }}
                      >
                        <Text
                          style={{
                            color: "#fff",
                            fontSize: 8,
                            textAlign: "center",
                            top: 4,
                          }}
                        >
                          {standard.code}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 32,
        }}
      >
        {SCALE_ITEMS.map((item) => (
          <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <Text style={{ color: "#2A2069", fontSize: 12 }}>{item.label}</Text>
            <View
              style={{
                width: 8,
                height: 8,
                backgroundColor: `${item.color}`,
                borderRadius: 2,
              }}
            ></View>
          </View>
        ))}
      </View>

      <View style={styles.bottomInfo}>
        <Text>{agencyTitle}</Text>
        <View style={styles.bottomInfo2}>
          <Text>قياس التحول الرقمي 2024 |</Text>
          <Text style={{ marginLeft: 15, marginRight: 20 }}>12</Text>
        </View>
      </View>
    </Page>
  );
}
