import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { useContext } from "react";
import { StageContext } from "../../../../contexts/stageContext";

export const useGetAuditors = ({ currentPage, filterValue }) => {
  // const surveyStageId = localStorage.getItem("surveyStageId");
  const { currentStage } = useContext(StageContext)
  const queryClient = useQueryClient();

  const surveyStageId = currentStage?.id;
  const phase4StageId = currentStage?.stageIds?.[3];

  const { data, isLoading: isGettingAllAuditors } = useQuery({
    queryKey: ["Auditors", currentPage, filterValue],
    queryFn: () =>
      axiosGetRequest(environment.GetAllReviewersWithAssignedStandards, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        POCOfficerId: pocOfficerId,
        ExpertId: expertId,
        KeyWord: filterValue,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
        stageId: phase4StageId,
      }),
      enabled: !!surveyStageId
  });

  const allAuditors = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);


  return {
    queryClient,
    allAuditors,
    totalCount,
    totalPages,
    data,
    isGettingAllAuditors,
  };
};
