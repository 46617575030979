import { useContext, useEffect, useState } from "react";
import StandardFinalAnswer from "../../standardAgencyAnswer";
import { StageContext } from "../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../answerContext";
import AppCheckbox from "../../../../../../../components/form/appCheckbox";
import AppTextarea from "../../../../../../../components/form/appTextarea";
import useCreatePocOfficerAnswer from "../useCreateAnswer/useCreatePocOfficerAnswer";

const PocOfficerFinalAnswer = ({
  setMenuElement,
  pocOfficerAnswerOnPhase2,
}) => {
  console.log("🚀 ~ pocOfficerAnswerOnPhase2:", pocOfficerAnswerOnPhase2)
  const { currentStage } = useContext(StageContext);
  const { createPocOfficerAnswer } = useCreatePocOfficerAnswer();
  const {
    newPocOfficerAnswer,
    setNewPocOfficerAnswer,
    pocOfficerDegree,
    newAgencyAnswer,
  } = useAnswerContext();

  console.log("🚀 ~ newAgencyAnswer:", newAgencyAnswer)
  const [checkedDegree, setCheckedDegree] = useState(null);

  const handleDegreeChange = (degree) => {
    setCheckedDegree(degree === checkedDegree ? "" : degree);

    setNewPocOfficerAnswer({
      ...newPocOfficerAnswer,
      checkedPocOfficerDegree: degree === checkedDegree ? "" : degree,
    });
  };

  useEffect(() => {
    setCheckedDegree(newPocOfficerAnswer?.checkedPocOfficerDegree);
  }, []);

  const handleChangeNote = (note) => {
    setNewPocOfficerAnswer({
      ...newPocOfficerAnswer,
      pocOfficerAnswerNote: note,
    });
  };

  const handleChangeDifferentNote = (note) => {
    setNewPocOfficerAnswer({
      ...newPocOfficerAnswer,
      differentBetweenOldAndNewExpertAnswerNote: note,
    });
  };

  const handleChangeDifferentAgencyNote = (note) => {
    setNewPocOfficerAnswer({
      ...newPocOfficerAnswer,
      differentBetweenAgencyAndExpertAnswerNote: note,
    });
  };

  const renderedDegrees = newPocOfficerAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedDegree?.value === item?.value}
      onChange={() => handleDegreeChange(item)}
    />
  ));

  console.log("🚀 ~ newPocOfficerAnswer?.checkedExpertDegree?.title:", newPocOfficerAnswer?.checkedExpertDegree?.title)

  console.log("🚀 ~ pocOfficerAnswerOnPhase2?.result?.lastAnswer:", pocOfficerAnswerOnPhase2?.result?.lastAnswer?.pocOfficerFinalCommitmentLevel?.title)

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createPocOfficerAnswer={createPocOfficerAnswer}
      label="إجابة ضابط الاتصال"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[200px]">{renderedDegrees}</div>
        <div className="flex-auto space-y-10">
          {pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.title !==
            newPocOfficerAnswer?.checkedPocOfficerDegree?.title && (
            <div className="mt-5 ml-6 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة النظام"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة النظام"
                style={{
                  marginBottom: "24px",
                }}
                value={newPocOfficerAnswer?.pocOfficerAnswerNote || ""}
                onChange={(e) => handleChangeNote(e.target.value?.trimStart())}
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
          {pocOfficerAnswerOnPhase2?.result?.lastAnswer
                ?.pocOfficerFinalCommitmentLevel?.title !==
            newPocOfficerAnswer?.checkedPocOfficerDegree?.title && (
            <div className="mt-5 ml-8 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة التحقق الأولى"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة التحقق الأولى"
                style={{
                  marginBottom: "24px",
                }}
                value={
                  newPocOfficerAnswer?.differentBetweenOldAndNewExpertAnswerNote ||
                  ""
                }
                onChange={(e) =>
                  handleChangeDifferentNote(e.target.value?.trimStart())
                }
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
          {newPocOfficerAnswer?.agencyFinalCommitmentLevel?.title !==
            newPocOfficerAnswer?.checkedPocOfficerDegree?.title && (
            <div className="mt-5 ml-8 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة الجهة"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة الجهة"
                style={{
                  marginBottom: "24px",
                }}
                value={
                  newPocOfficerAnswer?.differentBetweenAgencyAndExpertAnswerNote ||
                  ""
                }
                onChange={(e) =>
                  handleChangeDifferentAgencyNote(e.target.value?.trimStart())
                }
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
        </div>
      </div>
    </StandardFinalAnswer>
  );
};

export default PocOfficerFinalAnswer;
