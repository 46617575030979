import { useQueryClient, useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { useContext } from "react";
import { StageContext } from "../../../../contexts/stageContext";

export const useGetPocOfficers = ({ currentPage, filterValue }) => {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const queryClient = useQueryClient();

  const { currentStage } = useContext(StageContext)
  const phase4StageId = currentStage?.stageIds?.[3];

  const { data, isLoading: isGettingAllPocOfficers } = useQuery({
    queryKey: ["PocOfficers", currentPage, filterValue],
    queryFn: () =>
      axiosGetRequest(environment.GetAllPOCOfficersWithAssignedStandards, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        POCOfficerId: pocOfficerId,
        ExpertId: expertId,
        KeyWord: filterValue,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
        stageId: phase4StageId
      }),
    enabled: surveyStageId != null,
  });

  const allPocOfficers = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  return {
    queryClient,
    allPocOfficers,
    totalCount,
    totalPages,
    data,
    isGettingAllPocOfficers,
  };
};
