import NextBtn from "../../components/buttons/nextBtn";
import { showRequirement } from "../utils";
import answer from "./../../../../../../../assets/icons/answer.svg";

const NotesForAgencyPhase4 = ({
  setMenuElement,
  reviewerAnswerOnPhase2,
  phaseOneAgencyAnswer,
}) => {
  const reviewerNoteTemplate =
    reviewerAnswerOnPhase2?.result?.lastAnswer?.reviewerNoteForAgency
      ?.noteTemplate?.templateContent || "";
  const reviewerRequirementAnswers =
    reviewerAnswerOnPhase2?.result?.lastAnswer.reviewerRequirementAnswers;
  const reviewerNotContent =
    reviewerAnswerOnPhase2?.result?.lastAnswer?.reviewerNoteForAgency
      ?.content;

  const expertRequirementAnswers =
    reviewerAnswerOnPhase2?.result?.lastAnswer.expertRequirementAnswers;
  const expertNoteTemplate =
    reviewerAnswerOnPhase2?.result?.lastAnswer?.expertNoteForAgency
      ?.noteTemplate?.templateContent || "";
  const expertNotContent =
    reviewerAnswerOnPhase2?.result?.lastAnswer?.expertNoteForAgency?.content;

  const pocOfficerNoteTemplate =
    reviewerAnswerOnPhase2?.result?.pocOfficerAnswer?.noteForAgency
      ?.noteTemplate?.templateContent || "";
  const pocOfficerNotContent =
    reviewerAnswerOnPhase2?.result?.pocOfficerAnswer?.noteForAgency?.content;
  const standardRequirements = phaseOneAgencyAnswer?.standardRequirements?.map(
    (requirement) => {
      const reqAnswer =
        reviewerAnswerOnPhase2?.result?.pocOfficerAnswer?.pocOfficerRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.pocOfficerSubRequirementAnswers) {
        const { pocOfficerSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = pocOfficerSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...subReq,
              ...sub,
              id: subReq.id,
            };
          }
        );

        return {
          ...requirement,
          ...reqs,
          id: requirement.id,
          recommendation: "",
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    }
  );

  console.log({ expertRequirementAnswers })
  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={answer}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">الملاحظات</h1>
          </div>
        </div>

        <div className="w-[100%]  pr-[88px] flex flex-col  items-start overflow-y-auto h-[300px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات المدقق:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: reviewerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {reviewerRequirementAnswers?.map((requirement) => (
                <li>
                  {showRequirement(requirement)
                    ? requirement.standardRequirementTitle
                    : ""}
                  <ul>
                    {requirement?.reviewerSubRequirementAnswers?.map(
                      (subRequirement) => (
                        <>
                          {(subRequirement.decision == 0 ||
                            !subRequirement.decision) &&
                          subRequirement?.donotApply == false ? (
                            <li className="mx-10">
                              {subRequirement.standardRequirementTitle}
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: reviewerNotContent || "",
              }}
            ></div>
            {!reviewerNoteTemplate &&
              !reviewerNotContent &&
              !reviewerRequirementAnswers?.length && <div>لا يوجد ملاحظات</div>}
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الخبير:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: expertNoteTemplate,
              }}
            ></div>
            <ul className="px-2">
              {expertRequirementAnswers?.map((requirement) => (
                <li>
                  {showRequirement(requirement) ? requirement.standardRequirementTitle : ""}
                  <ul>
                    {requirement?.expertSubRequirementAnswers?.map(
                      (subRequirement) => (
                        <>
                          {(subRequirement.decision == 0 ||
                            !subRequirement.decision) &&
                          subRequirement?.donotApply == false ? (
                            <li className="mx-10">{subRequirement.subStandardRequirementTitle}</li>
                          ) : (
                            ""
                          )}
                        </>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: expertNotContent || "",
              }}
            ></div>
            {!standardRequirements?.length &&
              !expertNoteTemplate &&
              !expertNotContent && <div>لا يوجد ملاحظات</div>}
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات ضابط الاتصال:
            </h2>
            {/* <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertAnswer?.pocOfficerNoteForAgency || "لا يوجد ملاحظات",
              }}
            ></div> */}
            <div
              dangerouslySetInnerHTML={{
                __html: pocOfficerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {standardRequirements?.map((requirement) => (
                <li>
                  {showRequirement(requirement)
                    ? requirement.standardRequirementTitle
                    : ""}
                  <ul>
                    {requirement?.subStandardRequirements?.map(
                      (subRequirement) => (
                        <>
                          {(subRequirement.decision == 0 ||
                            !subRequirement.decision) &&
                          subRequirement?.donotApply == false ? (
                            <li className="mx-10">
                              {subRequirement.standardRequirementTitle}
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: pocOfficerNotContent || "",
              }}
            ></div>
            {!pocOfficerNoteTemplate &&
              !pocOfficerNotContent &&
              !standardRequirements?.length && <div>لا يوجد ملاحظات</div>}
          </div>
        </div>
      </div>
      <NextBtn onClick={() => setMenuElement(7)} />
    </>
  );
};

export default NotesForAgencyPhase4;
