import { Image, Page, StyleSheet } from "@react-pdf/renderer";
import Slide17 from "../assets/Slide17.jpg";

export function Page17({ globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <Page size="A4" style={styles.page}>
      <Image src={Slide17} />
    </Page>
  );
}
