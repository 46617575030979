import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../../services/environment";
import { axiosGetRequest } from "../../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetAuditorExpertFinishedStandards = ({ currentPage }) => {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const queryClient = useQueryClient();

  const { currentStage } = useContext(StageContext)
  const phase2StageId = currentStage?.stageIds?.[1];

  const { data, isLoading: isGettingAuditorExpertFinishedStandards } = useQuery(
    {
      queryKey: ["AuditorExpertFinishedStandards", currentPage, surveyStageId],
      queryFn: () =>
        axiosGetRequest(environment.getAuditorExpertFinishedStandards, {
          SurveyStageId: phase2StageId,
          SurveyId: surveyId(),
          ReviewerId: reviewerId,
          ExpertId: expertId,
          POCOfficerId: pocOfficerId,
          SkipCount: currentPage * 5,
          MaxResultCount: 5,
          StageId: phase2StageId,
        }),
      enabled: surveyStageId != null,
    }
  );

  const finishedStandards = data?.result?.reviewedAgencies;
  const allStandardsCount = data?.result?.totalAssignedAgenciesCount;
  const finishedStandardsCount = data?.result?.totalReviewedAgenciesCount;
  const totalPages = Math.ceil(finishedStandardsCount / 5);


  return {
    queryClient,
    finishedStandards,
    allStandardsCount,
    totalPages,
    data,
    finishedStandardsCount,
    isGettingAuditorExpertFinishedStandards,
  };
};
