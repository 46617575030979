import { useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { surveyId } from "../../../../helpers/isPermission";
import { useContext } from "react";
import { StageContext } from "../../../../contexts/stageContext";

export const useGetPhase4Stats = () => {
  const surveyFirstStageId = localStorage.getItem("stageForDashboard");
  const surveyCurrentStageId = localStorage.getItem("surveyStageId");

  const { currentStage } = useContext(StageContext);
  const phase4StageId = currentStage?.stageIds?.[3];

  const { data, isLoading: isGettingPhase2Stats } = useQuery({
    queryKey: ["Phase2DashboardStats"],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetPhase2Stats,
        {
          SurveyId: surveyId(),
          AgencyFirstStageId: surveyFirstStageId,
          ReviewFirstStageId: surveyCurrentStageId,
          stageId: phase4StageId,
        },
        true,
        signal
      ),
    enabled: surveyFirstStageId != null && surveyCurrentStageId != null,
  });

  const {
    data: phase4EditRequests,
    isLoading: isGettingPhase4EditRequests,
  } = useQuery({
    queryKey: ["Phase4EditRequests"],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetExpertManagerReAnswerRequestStatistics,
        {},
        true,
        signal
      ),
    enabled: surveyFirstStageId != null && surveyCurrentStageId != null,
  });

  const phase2Stats = data?.result;

  return {
    data,
    phase2Stats,
    isGettingPhase2Stats,
    phase4EditRequests: phase4EditRequests?.result,
    isGettingPhase4EditRequests,
  };
};
