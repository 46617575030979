import { Image, Page, StyleSheet, Text } from "@react-pdf/renderer";
import Slide1 from "../assets/Slide1.jpg";

export function IntroPage({ agencyTitle, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
    arabicText: {
      color: "#4CD4B0",
      fontSize: 18,
      textAlign: "right",
      position: "absolute",
      top: "55%",
      right: 92,
      fontFamily: "DiodrumArabic",
    },
  });

  return (
    <Page size="A4" style={styles.page}>
      <Image src={Slide1} />
      <Text style={styles.arabicText}>{agencyTitle}</Text>
    </Page>
  );
}
