import {
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import DownloadButton from "../../../components/DownloadButton";
import { DigitalTransformationReport } from "./DigitalTransformationReport";

// Register font
Font.register({
  family: "DiodrumArabic",
  src: "/static/media/DiodrumArabic-Medium.513de230123ccaeaa9ad.ttf",
});

const AgencyAnswerReport = () => {
  return (
    <div className="bg-white rounded-lg m-5 p-5">
      <PDFDownloadLink
        document={<DigitalTransformationReport />}
        fileName="التقرير النهائي.pdf"
        className="inline-block mb-5"
      >
        {({ loading }) => (
          <DownloadButton loading={loading}>
            تحميل التقرير النهائي (ملف PDf)
          </DownloadButton>
        )}
      </PDFDownloadLink>
      <PDFViewer width="100%" height="1000px">
        <DigitalTransformationReport />
      </PDFViewer>
    </div>
  );
};

export default AgencyAnswerReport;
