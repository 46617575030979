import { useQuery, useQueryClient } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetNotEnteredAgencies = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const surveyId = localStorage.getItem("surveyId");
  const { currentStage } = useContext(StageContext)
  const phaseThreeStageId = currentStage?.stageIds?.[2];

  const { data, isLoading: isGettingNotEnteredAgencies } = useQuery({
    queryKey: ["NotEnteredAgencies", currentPage, filterValue, phaseThreeStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getNotStartedAgencies,
        {
          KeyWord: filterValue,
          surveyId: surveyId,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          stageId: phaseThreeStageId,
        },
        true,
        signal
      ),
  });

  const notStartedAgencies = data?.result?.items;
  const notStartedAgenciesCount = data?.result?.totalCount;
  const totalPages = Math.ceil(notStartedAgenciesCount / 4);

  return {
    queryClient,
    data,
    notStartedAgencies,
    notStartedAgenciesCount,
    totalPages,
    isGettingNotEnteredAgencies,
  };
};
