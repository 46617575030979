import {plusLight, programsIcon} from "../../../../assets/icons/Index";
import NewHeader from "../../../../layouts/header/NewHeader";
import {DropdownMultiSelect, InputLabel, Title} from "../../../../components";
import {useEffect, useState} from "react";
import AppButton from "../../../../components/buttons/appButton";
import environment from "../../../../services/environment";
import useAxiosGetRequest from "../../../../helpers/customHooks/useAxiosGetRequest";
import {useHistory, useParams,} from "react-router-dom/cjs/react-router-dom.min";
import {axiosPutRequest} from "../../../../services/Request";
import {toast} from "react-toastify";
import NewDropdownMultiSelect from "../../../../components/NewDropDownMultiSelect";
import {NewForm} from "../../../../components/form/NewForm";
import {ErrorMessage} from "formik";
import NewSwitch from "../../../../components/NewSwitch";
import {useLocation} from "react-router";

function DetailsUpdatePage() {
  const history = useHistory();
  const { id } = useParams();
  const { state } = useLocation();

  // --------------------------------------- //
  // for multiSelect
  const [selectedAffiliatedEntities, setSelectedAffiliatedEntities] = useState(
    state?.subAgencies.map((ele) => {
      return {
        label: ele.nameInArabic,
        value: ele.id,
      };
    })
  );

  const handleChangeAffiliatedEntities = (value) => {
    setSelectedAffiliatedEntities(value || []);
  };

  const [getAffiliatedEntities, allAffiliatedEntities] = useAxiosGetRequest();

  useEffect(() => {
    getAffiliatedEntities(environment.getAllAgencyWithoutPagination);
  }, []);

  const allAffiliatedEntitiesOptions =
    allAffiliatedEntities?.result &&
    allAffiliatedEntities?.result?.map((item) => {
      return {
        label: item.nameInArabic || "",
        value: item.id,
        name: item.name || "",
      };
    });

  // --------------------------------------------- //

  const [updateLoading, setUpdateLoading] = useState(false);
  // const [inputFields, setInputFields] = useState(state.subAgencies);

  const areThereEntitiesValue =
    state?.subAgencies && state?.subAgencies?.length > 0;

  const [areThereEntities, setAreThereEntities] = useState(
    areThereEntitiesValue
  );
  const [isFirstTime, setIsFirstTime] = useState(state?.isFirstTimeInQiyas); // i want  it in current id from backend

  const [selectedRegion, setSelectedRegion] = useState({
    label: state.regionNameInArabic,
    value: state.regionId != 0 ? state.regionId : null,
  });
  const [selectedSector, setSelectedSector] = useState({
    label: state.agencySectorNameInArabic,
    value: state.agencySectorId != 0 ? state.agencySectorId : null,
  });
  const [selectedClassification, setSelectedClassification] = useState({
    label: state.agencyClassificationNameInArabic,
    value:
      state.agencyClassificationId != 0 ? state.agencyClassificationId : null,
  });
  const [selectedaCategories, setSelectedaCategories] = useState({
    label: state.agencyCategoryTitleArabic,
    value: state.agencyCategoryId != 0 ? state.agencyCategoryId : null,
  });
  const [weight, setWeight] = useState(state.weight);

  // const handleChange = (index, event) => {
  //   const updatedInputFields = [...inputFields];
  //   updatedInputFields[index].name = event.target.value;
  //   setInputFields(updatedInputFields);
  // };

  // const handleClearInputs = () => {
  //   const updatedInputFields = [...inputFields];
  //   updatedInputFields.map((ele) => (ele.name = ""));
  //   setInputFields(updatedInputFields);
  // };

  // const handleAddField = () => {
  //   setInputFields([...inputFields, { name: "" }]);
  // };

  // const handleDeleteField = (index) => {
  //   const updatedInputFields = inputFields.filter((_, i) => i !== index);
  //   setInputFields(updatedInputFields);
  // };
  // for allRegion multiSelect
  const [getAllRegion, allRegion] = useAxiosGetRequest();

  useEffect(() => {
    getAllRegion(environment.getAllRegion);
  }, []);

  const allRegionOptions =
    allRegion?.result?.items &&
    allRegion?.result?.items?.map((item) => {
      return { label: item.nameInArabic, value: item.id };
    });

  const handleSelectedRegion = (value) => {
    setSelectedRegion(value);
  };

  // for allSectors multiSelect
  const [getAllAgencySectors, allAgencySectors] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencySectors(environment.getAllAgencySectors);
  }, []);

  const allSectorsOptions =
    allAgencySectors?.result?.items &&
    allAgencySectors?.result?.items?.map((item) => {
      return { label: item.nameInArabic, value: item.id };
    });

  const handleSelectedSector = (value) => {
    setSelectedSector(value);
  };

  // for allClassifications multiSelect
  const [
    getAllAgencyClassification,
    allAgencyClassification,
  ] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencyClassification(environment.getAllAgencyClassification);
  }, []);

  const allClassificationsOptions =
    allAgencyClassification?.result?.items &&
    allAgencyClassification?.result?.items?.map((item) => {
      return { label: item.nameInArabic, value: item.id };
    });

  const handleSelectedClassification = (value) => {
    setSelectedClassification(value);
  };

  // for allAgencyCategory multiSelect
  const [getAllAgencyCategories, allAgencyCategories] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencyCategories(environment.getAllCategories);
  }, []);

  const allAgencyCategoriesOptions =
    allAgencyCategories?.result?.items &&
    allAgencyCategories?.result?.items?.map((item) => {
      return { label: item.titleArabic, value: item.id };
    });

  const handleSelectedaCategories = (value) => {
    setSelectedaCategories(value);
  };

  // const subAgencyNames = inputFields?.filter((ele) => ele.name !== "");
  const subAgencyNames = selectedAffiliatedEntities.map((ele) => {
    return {
      id: ele.value,
      name: ele.name,
      nameInArabic: ele.label,
    };
  });

  let body = {
    id: id,
    subAgencies: areThereEntities ? subAgencyNames : [],
    weight: weight,
    isFirstTimeInQiyas: isFirstTime,
    regionId: selectedRegion.value,
    agencyCategoryId: selectedaCategories.value,
    agencySectorId: selectedSector.value,
    agencyClassificationId: selectedClassification.value,
    isConfidentialAgency: state.isConfidentialAgency,
  };

  // handleSubmit
  const handleSubmit = async () => {
    setUpdateLoading(true);
    const res = await axiosPutRequest(environment.updateExtraFileds, body);
    setUpdateLoading(false);
    if (res?.success) {
      toast.info(`تم تحديث بيانات الجهة بنجاح`);
      history.push(`/entities/${id}/details`);
    } else {
      toast.error(res?.response?.data?.error?.message);
    }
  };

  const handleClear = () => {
    setWeight("");
    setSelectedaCategories({});
    setSelectedClassification({});
    setSelectedSector({});
    setSelectedRegion({});
    setIsFirstTime(false);
    setAreThereEntities(false);
    // handleClearInputs();
  };

  // --------------------------------------- //

  // ---------------------- validation -------------------
  const initialValues = {
    areThereEntities: false,
    selectedAffiliatedEntities: [],
    weight: "",
    selectedRegion: "",
    selectedSector: "",
    selectedClassification: "",
    selectedaCategories: "",
  };

  const validate = () => {
    const errors = {};

    if (areThereEntities) {
      if (!selectedAffiliatedEntities.length)
        errors.selectedAffiliatedEntities = "هذا الحقل مطلوب";
    }
    if (!weight || weight < 0 || weight > 100)
      errors.weight = "هذا الحقل لا بد ان يحتوي على رقم من 0 اٍلي 100";

    if (!selectedRegion) errors.selectedRegion = "هذا الحقل مطلوب";
    if (!selectedSector) errors.selectedSector = "هذا الحقل مطلوب";
    if (!selectedClassification)
      errors.selectedClassification = "هذا الحقل مطلوب";
    if (!selectedaCategories) errors.selectedaCategories = "هذا الحقل مطلوب";

    return errors;
  };
  // -----------------------------------------------------

  return (
    <div className="bg-white rounded-[30px] m-5 pb-4">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        title={"تفاصيل الجهة"}
        subTitle={"تعديل بيانات الجهة"}
        toPage={() => history.push(`/entities/${id}/details`)}
        textStyle={{ color: "white", fontSize: 18 }}
        seconed={true}
        withoutButton
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: "#2B2969", width: 200 }}
      />

      <div className="bg-[#F9F9FF] rounded-[30px] p-5 m-5">
        <div className="flex items-center justify-between mb-3">
          <p className="text-lg mb-4 ">تعديل بيانات الجهة</p>
        </div>
        <NewForm
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-5">
            {/*  */}
            <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3.5 mt-2">
                  <NewSwitch
                    value={areThereEntities}
                    setValue={setAreThereEntities}
                    label={"هل يوجد جهات تابعة لهذه الجهة؟"}
                  />
                </div>
                {areThereEntities && (
                  <div className="w-full space-x-1">
                    <NewDropdownMultiSelect
                      isClearable={true}
                      withoutLabel={false}
                      isSearchable={true}
                      isMultiple={true}
                      value={selectedAffiliatedEntities}
                      options={allAffiliatedEntitiesOptions || []}
                      handleChange={handleChangeAffiliatedEntities}
                      placeholder="اختر الجهات التابعة"
                      label={"الجهات التابعة"}
                      name={"selectedAffiliatedEntities"}
                      isRequired={true}
                    />
                    <ErrorMessage
                      className="text-red-700"
                      name="selectedAffiliatedEntities"
                      component="div"
                    />
                  </div>
                )}
              </div>
            </div>
            {/*  */}
            <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-3">
              <div className="flex gap-5">
                <div className="flex-1 space-y-1">
                  <DropdownMultiSelect
                    withoutLabel={false}
                    isSearchable={false}
                    value={selectedSector}
                    handleChange={handleSelectedSector}
                    label={"القطاع"}
                    options={allSectorsOptions}
                    isMultiple={false}
                    isRequired={false}
                    name={"selectedSector"}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="selectedSector"
                    component="div"
                  />
                </div>
                <div className="flex-1 space-y-1">
                  <DropdownMultiSelect
                    withoutLabel={false}
                    isSearchable={false}
                    value={selectedClassification}
                    handleChange={handleSelectedClassification}
                    label={"التصنيف"}
                    options={allClassificationsOptions}
                    isMultiple={false}
                    isRequired={false}
                    name={"selectedClassification"}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="selectedClassification"
                    component="div"
                  />
                </div>
              </div>

              <div className="flex gap-5">
                <div className="flex-1 space-y-1">
                  <DropdownMultiSelect
                    withoutLabel={false}
                    isSearchable={false}
                    value={selectedRegion}
                    handleChange={handleSelectedRegion}
                    label={"المنطقة"}
                    options={allRegionOptions}
                    isMultiple={false}
                    isRequired={false}
                    name={"selectedRegion"}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="selectedRegion"
                    component="div"
                  />
                </div>

                <div className="flex-1 space-y-1">
                  <DropdownMultiSelect
                    withoutLabel={false}
                    isSearchable={false}
                    value={selectedaCategories}
                    handleChange={handleSelectedaCategories}
                    label={"الفئة"}
                    options={allAgencyCategoriesOptions}
                    isMultiple={false}
                    isRequired={false}
                    name={"selectedaCategories"}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="selectedaCategories"
                    component="div"
                  />
                </div>
              </div>

              <div className="flex gap-5">
                <div className="flex-1 space-x-1">
                  <div className=" flex flex-col gap-3 ">
                    <InputLabel
                      value={weight}
                      type={"number"}
                      onChange={(e) => setWeight(e.target.value)}
                      name={"weight"}
                      label={"الوزن"}
                      withoutLabel={false}
                      isRequired={false}
                    />
                  </div>
                  <ErrorMessage
                    className="text-red-700"
                    name="weight"
                    component="div"
                  />
                </div>
                <div className="flex-1"></div>
              </div>
            </div>

            <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-3">
              {/* <label className="relative inline-flex items-center me-5 cursor-pointer  gap-3.5 mb-4">
                <input
                  type="checkbox"
                  checked={isFirstTime}
                  id="shareWithOtherIndicators"
                  className="sr-only peer"
                  onChange={() => setIsFirstTime((cur) => !cur)}
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-emerald-600"></div>
                <label
                  htmlFor="shareWithOtherIndicators"
                  className="text-gray-700 text-lg -mt-3"
                >
                  هل الجهة مشاركة لأول مرة في قياس؟
                </label>
              </label> */}

              <NewSwitch
                value={isFirstTime}
                setValue={setIsFirstTime}
                label={"هل الجهة مشاركة لأول مرة في قياس؟"}
              />
            </div>

            {/*  */}
            <div className="flex gap-5">
              <AppButton loading={updateLoading} type="submit" variant="purple">
                حفظ التعديلات
              </AppButton>

              <AppButton onClick={handleClear} variant="danger">
                مسح البيانات
              </AppButton>
            </div>
          </div>
        </NewForm>
      </div>
    </div>
  );
}

export default DetailsUpdatePage;
