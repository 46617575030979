import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { ProgressBar } from "../components/ProgressBar";
import { ScaleItem } from "../components/ScaleItem";

const SCALE_ITEMS = [
  { text: "الإبداع", style: "scaleItemLast", color: "#085133" },
  { text: "التكامل", style: "scaleItemSecond", color: "#1DC183" },
  { text: "التحسين", style: "scaleItemThird", color: "#FF9D00" },
  { text: "الإتاحة", style: "scaleItemFourth", color: "#FF6B02" },
  { text: "البناء", style: "scaleItemFirst", color: "#B40002" },
];

function numberToFixed(num = 0, d = 2) {
  const number = Number.isFinite(num) ? Number(num) : 0;
  return number.toFixed(d);
}

export function Page7({
  perspectives,
  qiyasObj,
  agencyTitle,
  year = 2024,
  globalStyles,
}) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <Page style={styles.page} author="test">
      <Text style={styles.sectionTitle}>03. ملخص نتيجة {agencyTitle}</Text>

      <Text style={styles.subtitle}>
        نستعرض ناتج نتيجة التحول الرقمي لجهة {agencyTitle} وفقاً لمعايير التزام
        بالمحاور الرئيسية للأستراتيجية للتحول الرقمي {year}، إضافة إلى تقييم كل
        محور، وذلك استناداً على البيانات والمعلومات المدخلة عبر نظام القياس.
      </Text>
      <Text style={styles.sectionSecondaryTitle}>نتيجة التحول الرقمي</Text>
      <View style={styles.progressHeaderContainer}>
        <Text style={styles.progressHeaderLabel}>المنظور</Text>
        <Text style={styles.progressHeaderWeightLabel}>وزن المنظور​</Text>
        <View style={styles.progressBarWrapper}>النتيجة</View>
      </View>
      {perspectives?.perspectivesList?.map((item, index) => (
        <ProgressBar
          key={index}
          label={item.title}
          percentage={item.resut?.toFixed(2)}
          weight={item.weight}
          color="#7C32C9"
        />
      ))}

      <View style={styles.totalScore}>
        <Text style={styles.totalScoreLabel}>: نتيجة التحول الرقمي للجهة</Text>
        <View style={styles.totalScoreTextWrapper}>
          <Text style={styles.totalScoreText}>
            {qiyasObj?.finalResult?.toFixed(2)} %
          </Text>
        </View>
      </View>

      <View style={styles.scaleContainer}>
        <Text style={styles.sectionSecondaryTitle}> النتيجة المكافئة​</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity: qiyasObj?.finalResult >= 80 ? 1 : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity:
                qiyasObj?.finalResult >= 60 && qiyasObj?.finalResult < 80
                  ? 1
                  : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity:
                qiyasObj?.finalResult >= 40 && qiyasObj?.finalResult < 60
                  ? 1
                  : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity:
                qiyasObj?.finalResult >= 20 && qiyasObj?.finalResult < 40
                  ? 1
                  : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity: qiyasObj?.finalResult < 20 ? 1 : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>, margin:"auto"
            <Text style={{ color: "#2A2069", fontSize: 10 }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
        </View>

        <View style={styles.scale}>
          {SCALE_ITEMS.slice().map((item, index) => (
            <ScaleItem key={index} style={styles[item.style]}>
              {item.text}
            </ScaleItem>
          ))}
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity: qiyasObj?.previousFinalResult >= 80 ? 1 : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity:
                qiyasObj?.previousFinalResult >= 60 &&
                qiyasObj?.previousFinalResult < 80
                  ? 1
                  : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity:
                qiyasObj?.previousFinalResult >= 40 &&
                qiyasObj?.previousFinalResult < 60
                  ? 1
                  : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity:
                qiyasObj?.previousFinalResult >= 20 &&
                qiyasObj?.previousFinalResult < 40
                  ? 1
                  : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity: qiyasObj?.previousFinalResult < 20 ? 1 : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>

            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
        </View>
      </View>

      <View style={styles.bottomInfo}>
        <Text>{agencyTitle}</Text>
        <View style={styles.bottomInfo2}>
          <Text>قياس التحول الرقمي 2024 |</Text>
          <Text style={{ marginLeft: 15, marginRight: 20 }}>8</Text>
        </View>
      </View>
    </Page>
  );
}
