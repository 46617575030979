import { useQueryClient, useQuery } from "@tanstack/react-query";
import environment from "../../../../services/environment";
import { axiosGetRequest } from "../../../../services/Request";
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { StageContext } from "../../../../contexts/stageContext";
import { useContext } from "react";

export const useGetAuditorExpertAxis = ({ currentPage }) => {
  // const { currentStage } = useContext(StageContext);
  const surveyStageId = localStorage.getItem("surveyStageId");
  const queryClient = useQueryClient();

  const { currentStage } = useContext(StageContext)
  const phase4StageId = currentStage?.stageIds?.[3];

  const { data, isLoading: isGettingAuditorExpertAxis } = useQuery({
    queryKey: ["AuditorExpertAxis", currentPage, surveyStageId],
    queryFn: () =>
      axiosGetRequest(environment.getAuditorExpertAxis, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        ExpertId: expertId,
        POCOfficerId: pocOfficerId,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
        StageId: phase4StageId,
      }),
    enabled: surveyStageId != null,
  });

  const axis = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(data?.result?.totalCount / 5);


  return {
    queryClient,
    axis,
    totalCount,
    totalPages,
    data,
    isGettingAuditorExpertAxis,
  };
};
