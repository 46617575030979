import { errorToast, successToast } from "../../../../../../../helpers/toast";
import { validateAnswerNote } from "../../../../../../../helpers/utils";
import { axiosPostRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { useAnswerContext } from "../../answerContext";
import usePrevExpertManagerAnswer from "../useGetPrevAnswer/usePrevExpertManagerAnswer";
import { showRequirement } from "../utils";

const useCreateExpertManagerAnswer = ( expertMangerAnswerOnPhase2 ) => {
  const { getPrevExpertManagerAnswer } = usePrevExpertManagerAnswer();

  const {
    newExpertManagerAnswer,
    setCreateAnswerLoading,
    expertManagerDegree,
    expertManagerAnswer,
    newAgencyAnswer,
  } = useAnswerContext();

  const createExpertManagerAnswer = async (
    standardId,
    entityId,
    currentStage
  ) => {
    const expertManagerRequirementAnswers =
      newExpertManagerAnswer?.standardRequirements?.map((req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            note: req?.subReq || "",
            recommendation: req?.subReq || "",
            donotApply: subReq?.donotApply,
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          note: req?.note || "",
          recommendation: req?.note || "",
          donotApply: req?.donotApply,
          expertManagerSubRequirementAnswers: subStandardRequirements,
        };
      });

    const surveyExpertManagerAnswerStandardProofs =
      newExpertManagerAnswer?.standardProofs
        ?.filter((proof) => proof?.expertManagerProofNotes)
        .map((proof) => {
          return {
            standardProofId: proof?.standardProofId || null,
            expertManagerProofNotes: proof?.expertManagerProofNotes || "",
          };
        }) || [];

    // validation
    if (newExpertManagerAnswer?.checkedExpertManagerDegree?.value == null) {
      return errorToast("لابد من وجود اجابة للتدقيق");
    }

    const data = {
      standardId: Number(standardId),
      surveyStageId: Number(currentStage),
      expertManagerRequirementAnswers,
      surveyExpertManagerAnswerStandardProofs,
      noteForAgency: {
        // content:
        //   newExpertManagerAnswer?.selectedTemplatesForAgency == undefined
        //     ? "<p>لا يوجد</p>" +
        //       "<ul>" +
        //       newExpertManagerAnswer?.newRequirementsAgencyForExpertManager +
        //       "</ul>"
        //     : newExpertManagerAnswer?.selectedTemplatesForAgency +
        //       "<ul>" +
        //       newExpertManagerAnswer?.newRequirementsAgencyForExpertManager +
        //       "</ul>",
        // template:
        //   newExpertManagerAnswer?.expertManagerSelectedTemplateForAgency,

        unCheckRequirement:
          newExpertManagerAnswer?.newRequirementsAgencyForExpertManager ==
          undefined
            ? ""
            : `<ul style="padding : 6px 0 ">` +
              newExpertManagerAnswer?.newRequirementsAgencyForExpertManager +
              "</ul>",
        template: "",
        content: newExpertManagerAnswer?.expertManagerSelectedTemplateForAgency,
        noteTemplateId: newExpertManagerAnswer?.selectedTemplatesForAgency,
      },
      noteForReviewer: {
        content:
          // newExpertManagerAnswer?.selectedTemplatesForReviewer +
          "<ul>" +
          newExpertManagerAnswer?.newRequirementsReviewerForExpertManager +
          "</ul>",
        template:
          newExpertManagerAnswer?.expertManagerSelectedTemplateForReviewer,
      },
      expertManagerId: Number(localStorage.getItem("userId")),
      agencyId: Number(entityId),
      finalDegree:
        newExpertManagerAnswer?.checkedExpertManagerDegree?.fixedDegree,
      donotApply: newExpertManagerAnswer?.donotApply,
      finalCommitmentLevelId:
        newExpertManagerAnswer?.checkedExpertManagerDegree?.value,
      selfEvaluationCommitmentLevelId:
        expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel?.id ||
        null,
      selfEvaluationDegree:
        expertManagerDegree?.expertManagerSelfEvaluationDegree || 0,

      expertManagerAnswerNote:
        expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel
          ?.title !== newExpertManagerAnswer?.checkedExpertManagerDegree?.title
          ? newExpertManagerAnswer?.expertManagerAnswerNote
          : null,
      differentBetweenOldAndNewExpertAnswerNote:
        expertManagerAnswer?.reviewerFinalCommitmentLevel?.title !==
        newExpertManagerAnswer?.checkedExpertDegree?.title
          ? newExpertManagerAnswer?.differentBetweenOldAndNewExpertAnswerNote
          : null,
      differentBetweenAgencyAndExpertAnswerNote:
        newAgencyAnswer?.checkedAgencyDegree?.title !==
        newExpertManagerAnswer?.checkedExpertDegree?.title
          ? newExpertManagerAnswer?.differentBetweenAgencyAndExpertAnswerNote
          : null,

      visitId: null,
      visitRecommendation: "",
      needVisit: false,
      visitNote: "",
    };

    if (
      expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel?.title !==
        newExpertManagerAnswer?.checkedExpertManagerDegree?.title &&
      !newExpertManagerAnswer?.expertManagerAnswerNote
    ) {
      errorToast(
        "الرجاء كتابة سبب تغيير اجابتك عن إجابة الدرجة المقترحة من النظام فى إجابة الخبير"
      );
      return;
    }

    if (
      expertMangerAnswerOnPhase2?.result?.lastAnswer
                ?.expertFinalCommitmentLevel?.title !==
        newExpertManagerAnswer?.checkedExpertManagerDegree?.title &&
      !newExpertManagerAnswer?.differentBetweenOldAndNewExpertAnswerNote
    ) {
      errorToast("الرجاء كتابة سبب تغيير اجابتك عن إجابة التحقق الأولى");
      return;
    }

    if (
      newExpertManagerAnswer?.agencyFinalCommitmentLevel?.title !==
        newExpertManagerAnswer?.checkedExpertManagerDegree?.title &&
      !newExpertManagerAnswer?.differentBetweenAgencyAndExpertAnswerNote
    ) {
      errorToast("الرجاء كتابة سبب تغيير اجابتك عن إجابة الجهة");
      return;
    }

    if (validateAnswerNote(data?.expertManagerAnswerNote)) {
      errorToast("سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز");
      return;
    }

    if (validateAnswerNote(data?.differentBetweenOldAndNewExpertAnswerNote)) {
      errorToast("سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز");
      return;
    }

    if (validateAnswerNote(data?.differentBetweenAgencyAndExpertAnswerNote)) {
      errorToast("سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز");
      return;
    }

    const hasRequiredRequirements =
      newExpertManagerAnswer?.standardRequirements?.filter((requirement) =>
        showRequirement(requirement)
      )?.length > 0;

    if (
      hasRequiredRequirements &&
      !newExpertManagerAnswer?.selectedTemplatesForAgency
    ) {
      errorToast("الرجاء اختيار قالب الملاحظات للجهة");
      return;
    }

    // if (
    //   hasRequiredRequirements &&
    //   !newExpertManagerAnswer?.expertManagerSelectedTemplateForAgency
    // ) {
    //   errorToast("الرجاء كتاية ملاحظات التدقيق للجهة");
    //   return;
    // }

    setCreateAnswerLoading(true);
    const res = await axiosPostRequest(
      environment.addExpertManagerAnswer,
      data
    );
    if (res?.success) {
      successToast("تم اضافة الاجاية بنجاح");
      getPrevExpertManagerAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage)
      );
    }
    setCreateAnswerLoading(false);
  };
  return { createExpertManagerAnswer };
};

export default useCreateExpertManagerAnswer;
