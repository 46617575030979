import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getAllNotifications } from "../../../services/api/notificationApi";

export const useGetNotifications = ({ currentPage, option, date }) => {
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingNotifications } = useQuery({
    queryKey: ["notifications", currentPage, option.value, date],
    queryFn: () =>
      getAllNotifications({
        SkipCount: currentPage * 6,
        MaxResultCount: 6,
        IsRead: option.value,
        Date: date,
      }),
  });

  const notifications = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(data?.result?.totalCount / 6);

  return {
    queryClient,
    notifications,
    totalCount,
    totalPages,
    data,
    isGettingNotifications,
  };
};
