import { useEffect } from "react";
import {
  interlocator,
  prespictive,
  standards,
  unAnswerdStandard,
} from "../../../assets/icons/dashboard";
import DashboardStats from "../../../components/dashboard/DashboardStats";
import { useDashboardContext } from "../../../contexts/DashboardContext";
import { useGetPhase4Stats } from "./api/useGetPhase4Stats";
import {
  documentsFileCheckmark,
  documentsFileExcel,
  documentsFilesCopy,
  documentsFileWarningError,
} from "../../../assets/icons/Index";
import { useGetPhase4GetActiveExpertsReviewers } from "./api/useGetPhase4GetActiveExpertsReviewers";

function AdminDashboardStatsPhase4() {
  const {
    phase2Stats,
    isGettingPhase2Stats,
    phase4EditRequests,
    isGettingPhase4EditRequests,
  } = useGetPhase4Stats();

  const { activeExpertsReviewers, isGetPhase4GetActiveExpertsReviewers } =
    useGetPhase4GetActiveExpertsReviewers();
  const { setLoading, setAdminDashboardStats } = useDashboardContext();

  useEffect(() => {
    setAdminDashboardStats((prevStats) => ({
      ...prevStats,
      reviewersCount: phase2Stats?.reviewersCount,
      editRequests: phase4EditRequests,
    }));
    setLoading(isGettingPhase2Stats || isGettingPhase4EditRequests);
  }, [
    phase2Stats?.reviewersCount,
    isGettingPhase2Stats,
    phase4EditRequests,
    isGettingPhase4EditRequests,
    setAdminDashboardStats,
    setLoading,
  ]);

  const list = [
    {
      id: 1,
      title: "نسبة اٍنجاز التدقيق",
      number: `${Math.round(phase2Stats?.reviewOperationRate)}%`,
      icon: unAnswerdStandard,
      color: "#25f81e26",
    },
    {
      id: 2,
      title: "عدد المعايير الكلية",
      number: phase2Stats?.totalAgenciesCountInAllStandards,
      icon: unAnswerdStandard,
      color: "#a6edff8f",
    },
    {
      id: 3,
      title: "عدد المعايير الكلية المجابة",
      number: phase2Stats?.totalAnsweredAgenciesCountInAllStandards,
      icon: unAnswerdStandard,
      color: "#f7dffdcc",
    },
    {
      id: 4,
      title: "عدد المعايير الكلية المدققة",
      number: phase2Stats?.totalReviewedAgenciesCountInAllStandards,
      icon: unAnswerdStandard,
      color: "#ffa21726",
    },
    {
      id: 5,
      title: "عدد المدققين",
      number: phase2Stats?.reviewersCount,
      icon: prespictive,
      color: "#67d4ff26",
    },
    {
      id: 6,
      title: "عدد الخبراء",
      number: phase2Stats?.expertCount,
      icon: interlocator,
      color: "#f8251e26",
    },
    {
      id: 7,
      title: "عدد رؤساء الخبراء",
      number: phase2Stats?.expertManagerCount,
      icon: standards,
      color: "#0046de25",
    },
    {
      id: 8,
      title: "عدد ضباط الاتصال الداخليين",
      number: phase2Stats?.pocOfficerCount,
      icon: unAnswerdStandard,
      color: "#25f81e26",
    },
    {
      id: 13,
      title: "الجهات الغير مستجيبة",
      number: phase2Stats?.unRecognizedAgenciesCount,
      icon: unAnswerdStandard,
      color: "#67d4ff26",
    },
    // {
    //   id: 14,
    //   title: "المعايير المجابة للجهات ",
    //   number: phase2Stats?.recognizedStandardsCount,
    //   icon: unAnswerdStandard,
    //   color: "#f7dffdcc",
    // },
    {
      id: 15,
      title: "المعايير الغير المجابة للجهات  ",
      number: phase2Stats?.totalAnsweredAgenciesCountInAllStandards
        ? 15769 - phase2Stats?.totalAnsweredAgenciesCountInAllStandards
        : 0,
      icon: unAnswerdStandard,
      color: "#0046de25",
    },
    {
      id: 16,
      title: "الإجابات المدققة بواسطة ضابط الاتصال",
      number: phase2Stats?.totalNewCardAgencyReviewsByPocOfficer,
      icon: unAnswerdStandard,
      color: "#0046de25",
    },
    {
      id: 17,
      title: "الإجابات المدققة بواسطة المدقق",
      number: phase2Stats?.totalNewCardAgencyReviewsByReviewers,
      icon: unAnswerdStandard,
      color: "#0046de25",
    },
    {
      id: 18,
      title: "الإجابات المدققة بواسطة الخبير",
      number: phase2Stats?.totalNewCardAgencyReviewsByExpert,
      icon: unAnswerdStandard,
      color: "#0046de25",
    },
    {
      id: 20,
      title: "المدققين النشطين",
      number: activeExpertsReviewers?.activeReviewersCount,
      icon: prespictive,
      color: "#0046de25",
    },
    {
      id: 19,
      title: "الخبراء النشطين",
      number: activeExpertsReviewers?.activeExpertsCount,
      icon: interlocator,
      color: "#0046de25",
    },
    {
      id: 9,
      title: "طلبات التعديل",
      number: phase4EditRequests?.all,
      icon: documentsFilesCopy,
      color: "#DBD9FF",
    },
    {
      id: 10,
      title: "طلبات التعديل قيد المراجعة",
      number: phase4EditRequests?.inProgress,
      icon: documentsFileExcel,
      color: "#FFF0DA",
    },
    {
      id: 11,
      title: "طلبات التعديل تمت الموافقة عليها",
      number: phase4EditRequests?.approved,
      icon: documentsFileCheckmark,
      color: "#D6FFEF",
    },
    {
      id: 12,
      title: "طلبات التعديل تم رفضها",
      number: phase4EditRequests?.rejected,
      icon: documentsFileWarningError,
      color: "#FFE4E4",
    },
  ];

  return (
    <DashboardStats
      lists={list}
      loading={
        isGettingPhase2Stats ||
        isGettingPhase4EditRequests ||
        isGetPhase4GetActiveExpertsReviewers
      }
    />
  );
}

export default AdminDashboardStatsPhase4;
