import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useOutsideClick } from "../hooks/useOutsideClick";
import AppButton from "./buttons/appButton";
import { useHistory, useParams } from "react-router";
import { permissionsCheck } from "../helpers/utils";

const DrawerFilter = ({
  children,
  handleSearch,
  handleClear,
  type = "button",
  height,
}) => {
  const { push, location } = useHistory();
  const { programId } = useParams();
  const [showDrawer, setShowDrawer] = useState(false);
  const { thisWindow } = useOutsideClick(() => setShowDrawer(false));

  return (
    <div className="relative">
      <div className="flex items-center gap-5">
        {permissionsCheck("Pages.UserType.ExpertManager") &&
          location?.pathname?.includes("/standards") && (
            <AppButton
              className="text-xl font-semibold px-12"
              onClick={() => {
                push(`/programs/${programId}/edit-requests`);
              }}
            >
              طلبات التعديل
            </AppButton>
          )}
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setShowDrawer((cur) => !cur);
          }}
          className="rounded-full px-8 py-4 text-white transition-all duration-300  bg-green hover:bg-emerald-600"
        >
          <div className="flex justify-between items-center gap-3">
            <FontAwesomeIcon icon={faFilter} />
            <div className="">تصفية حسب</div>
          </div>
        </button>
      </div>

      {showDrawer && (
        <div className="fixed inset-0 z-30 bg-black opacity-50"></div>
      )}

      <div
        ref={thisWindow}
        className={`${
          showDrawer ? "translate-x-0" : "-translate-x-full"
        } fixed top-0 left-0 z-40 h-screen w-full lg:w-[35%] bg-white flex flex-col shadow-xl ease-in-out duration-300`}
      >
        {/* drawer header */}
        <div className="w-full flex flex-row justify-between items-center bg-gray-200 p-5">
          <div className="flex justify-between w-full items-center">
            <p className="text-qiyas_violet text-lg font-semibold">التصفية</p>

            <button
              onClick={() => setShowDrawer((cur) => !cur)}
              className="bg-gray-100 p-2 w-8 h-8 flex justify-center items-center rounded-md"
            >
              <FontAwesomeIcon
                icon={faXmark}
                fontSize="20px"
                color="#505050aa"
              />
            </button>
          </div>
        </div>

        {/* drawer body */}
        <div className={`flex flex-col gap-3 p-5 overflow-y-auto h-full mb-20`}>
          {children}
        </div>

        {/* drawer footer */}
        <div className="absolute w-full bottom-0 flex flex-row justify-between items-center bg-gray-200 p-5">
          <div className="flex gap-4">
            <button
              type={type}
              onClick={() => {
                setShowDrawer((cur) => !cur);
                handleSearch();
              }}
              className="px-8 py-3 text-white transition-all duration-300  bg-emerald-500 hover:bg-emerald-600 flex items-center gap-3"
            >
              <FontAwesomeIcon icon={faFilter} />
              <span className="-mt-3 ">تطبيق البحث</span>
            </button>

            <button
              onClick={() => handleClear()}
              className="px-8 py-3 text-qiyas_violet border-2 border-[#333] transition-all duration-300 hover:bg-gray-300 flex items-center gap-3"
            >
              <FontAwesomeIcon icon={faXmark} />
              <span className="-mt-3 ">مسح البحث</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerFilter;
