import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { ScaleItem } from "../components/ScaleItem";
import { ProgressBar2 } from "../components/ProgressBar2";

const SCALE_ITEMS = [
  { text: "الإبداع", style: "scaleItemLast", color: "#085133" },
  { text: "التكامل", style: "scaleItemSecond", color: "#1DC183" },
  { text: "التحسين", style: "scaleItemThird", color: "#FF9D00" },
  { text: "الإتاحة", style: "scaleItemFourth", color: "#FF6B02" },
  { text: "البناء", style: "scaleItemFirst", color: "#B40002" },
];

function numberToFixed(num = 0, d = 2) {
  const number = Number.isFinite(num) ? Number(num) : 0;
  return number.toFixed(d);
}

export function Page9({ agencyTitle, qiyasObj, agencyResults, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  const Equivalent_Result = [
    {
      percentage:
        (agencyResults?.benaaStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.benaaStageCount,
      label: "البناء",
      style: "scaleItemFirst",
      color: "#B40002",
    },
    {
      percentage:
        (agencyResults?.etahaStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.etahaStageCount,
      label: "الإتاحة",
      style: "scaleItemFourth",
      color: "#FF6B02",
    },
    {
      percentage:
        (agencyResults?.tahseenStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.tahseenStageCount,
      label: "التحسين",
      style: "scaleItemThird",
      color: "#FF9D00",
    },
    {
      percentage:
        (agencyResults?.takamolStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.takamolStageCount,
      label: "التكامل",
      style: "scaleItemSecond",
      color: "#1DC183",
    },
    {
      percentage:
        (agencyResults?.ebdaaStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.ebdaaStageCount,
      label: "الإبداع",
      style: "scaleItemLast",
      color: "#085133",
    },
  ];

  return (
    <Page style={styles.page} author="test">
      <Text style={styles.sectionTitle}>
        04. مقارنة نتيجة الجهة مع نتائج المجموعة{" "}
        {qiyasObj?.agencyClassificationTitle}
      </Text>

      <Text style={styles.subtitle}>
        : يبين الشكل أدناه نتيجة الجهة بالمقارنة مع متوسط نتيجة المجموعة التي
        تنتمي لها{" "}
      </Text>

      <View style={{ ...styles.scaleContainer, marginBottom: 0 }}>
        <Text style={styles.sectionSecondaryTitle}> النتيجة المكافئة​</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity: qiyasObj?.finalResult >= 80 ? 1 : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity:
                qiyasObj?.finalResult >= 60 && qiyasObj?.finalResult < 80
                  ? 1
                  : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity:
                qiyasObj?.finalResult >= 40 && qiyasObj?.finalResult < 60
                  ? 1
                  : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity:
                qiyasObj?.finalResult >= 20 && qiyasObj?.finalResult < 40
                  ? 1
                  : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              opacity: qiyasObj?.finalResult < 20 ? 1 : 0,
            }}
          >
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>, margin:"auto"
            <Text style={{ color: "#2A2069", fontSize: 10 }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
        </View>

        <View style={styles.scale}>
          {SCALE_ITEMS.slice().map((item, index) => (
            <ScaleItem key={index} style={styles[item.style]}>
              {item.text}
            </ScaleItem>
          ))}
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity: qiyasObj?.previousFinalResult >= 80 ? 1 : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity:
                qiyasObj?.previousFinalResult >= 60 &&
                qiyasObj?.previousFinalResult < 80
                  ? 1
                  : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity:
                qiyasObj?.previousFinalResult >= 40 &&
                qiyasObj?.previousFinalResult < 60
                  ? 1
                  : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity:
                qiyasObj?.previousFinalResult >= 20 &&
                qiyasObj?.previousFinalResult < 40
                  ? 1
                  : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
          <View
            label="indicator"
            style={{
              textAlign: "center",
              marginTop: 6,
              opacity: qiyasObj?.previousFinalResult < 20 ? 1 : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>

            <Text style={{ color: "#2A2069", fontSize: 10, margin: "auto" }}>
              {numberToFixed(qiyasObj?.previousFinalResult)}
            </Text>
            <Text style={{ fontSize: 6 }}>{agencyTitle}</Text>
          </View>
        </View>
      </View>

      {Equivalent_Result.map((item, index) => (
        <ProgressBar2
          key={index}
          label={item.label}
          percentage={item.percentage}
          count={item.count}
          color={item.color || "#7C32C9"} // Use the updated color
        />
      ))}

      <Text
        style={{ color: "#2A2069", fontSize: 12, marginHorizontal: "auto" }}
      >
        يبين الشكل أعلاه مقارنة نتيجة الجهة مع الجهات الحكومية الأخرى لنفس
        المجموعة​{" "}
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 8,
        }}
      >
        {Equivalent_Result.map((item, index) => (
          <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <View
              style={{ width: 8, height: 8, backgroundColor: `${item.color}` }}
            ></View>

            <Text style={{ color: "#2A2069", fontSize: 12 }}>{item.label}</Text>
          </View>
        ))}
      </View>

      <View style={styles.bottomInfo}>
        <Text>{agencyTitle}</Text>
        <View style={styles.bottomInfo2}>
          <Text>قياس التحول الرقمي 2024 |</Text>
          <Text style={{ marginLeft: 15, marginRight: 20 }}>10</Text>
        </View>
      </View>
    </Page>
  );
}
