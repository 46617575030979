import { useContext, useEffect, useState } from "react";
import NewTable from "../../../../components/NewTable";
import NewCard from "../../../../components/dashboard/NewCard";
import { getStandardRatioBadge } from "../../../../helpers/utils";
import { useGetPocOfficers } from "../api/useGetPocOfficers"; // Assume you have a similar hook for POC officers
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import { axiosGetRequest } from "../../../../services/Request";
import environment from "../../../../services/environment";
import {
  expertId,
  reviewerId,
  surveyId,
} from "../../../../helpers/isPermission";
import { useDashboardContext } from "../../../../contexts/DashboardContext";
import { StageContext } from "../../../../contexts/stageContext";

function PocOfficerCardPhase4() {
  const surveyStageId = localStorage.getItem("surveyStageId");
  const [currentPage, setCurrentPage] = useState(0);
  const [filterValue, setFilterValue] = useState(null);
  
  const { setLoading, setPocOfficersDashboardStats } = useDashboardContext(); // Updated context
  const { currentStage } = useContext(StageContext)
  const phase4StageId = currentStage?.stageIds?.[3];

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const {
    allPocOfficers,
    totalPages,
    isGettingAllPocOfficers,
    totalCount,
  } = useGetPocOfficers({
    currentPage,
    filterValue,
  });

  useEffect(() => {
    setPocOfficersDashboardStats((prevStats) => ({
      ...prevStats,
      pocOfficersCount: totalCount,
    }));
    setLoading(isGettingAllPocOfficers);
  }, [totalCount, isGettingAllPocOfficers]);

  const columns = [
    {
      name: "اسم ضابط الاتصال",
      sortable: true,
      cell: (info) => (
        <div className="text-[#292069] font-semibold">{info.name}</div>
      ),
    },
    {
      name: "عدد المعايير الكلية المسندة الى ضابط الاتصال",
      sortable: true,
      center: true,
      cell: (info) => (
        <div className="text-center">{info.assignedAgenciesCount}</div>
      ),
    },
    {
      name: "عدد المعايير الكلية المجابة بواسطة الجهات",
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="text-center">{row.answeredAgenciesCount}</div>
      ),
    },
    {
      name: "عدد المعايير الكلية المدققة",
      sortable: true,
      center: true,
      cell: (info) => (
        <div className="text-center">{info.reviewedAgenciesCount}</div>
      ),
    },
    {
      name: "نسبة اٍنجاز ضابط الاتصال",
      accessorKey: "reviewRatio",
      selector: (row) => row.reviewRatio,
      center: true,
      sortable: true,
      cell: (row) => getStandardRatioBadge(row.reviewRatio),
    },
  ];

  // excel
  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(
      environment.GetAllPocOfficersWithAssignedStandards,
      {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        ExpertId: expertId,
        KeyWord: filterValue,
        SkipCount: 0,
        MaxResultCount: 10000,
        StageId: phase4StageId,
      }
    );

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        "اسم ضابط الاتصال": ele?.name,
        "عدد المعايير الكلية المسندة الى ضابط الاتصال":
          ele?.assignedAgenciesCount,
        "عدد المعايير الكلية المجابة بواسطة الجهات": ele?.answeredAgenciesCount,
        "عدد المعايير الكلية المدققة": ele?.reviewedAgenciesCount,
        "نسبة اٍنجاز ضابط الاتصال": ele?.reviewRatio,
      };
    });

    downloadExcel("ضباط الاتصال", dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <NewCard title={`ضباط الاتصال (${totalCount})`}>
      <NewTable
        withSearch
        onSearch={handleFilter}
        withExcel
        excelLoading={excelLoading}
        onExportExcel={handleExcelExport}
        loading={isGettingAllPocOfficers}
        columns={columns}
        data={allPocOfficers || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
}

export default PocOfficerCardPhase4;
