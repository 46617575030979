import { useAnswerContext } from "../../answerContext";
import NextBtn from "../../components/buttons/nextBtn";
import { showRequirement } from "../utils";
import answer from "./../../../../../../../assets/icons/answer.svg";

export interface IReviewerAnswer {
  newRequirementsAgency:               string;
  agencyName:                          string;
  standardRequirements:                ExpertRequirementsAnswer[];
  checkedReviewerDegree:               null;
  expertRequirementsAnswer:            ExpertRequirementsAnswer[];
  pocOfficerRequirementsAnswer:        ExpertRequirementsAnswer[];
  surveyDegrees:                       AgencyFinalCommitmentLevel[];
  standardProofs:                      StandardProof[];
  templateList:                        TemplateList[];
  agencyFinalCommitmentLevel:          AgencyFinalCommitmentLevel;
  agencySelfEvaluationCommitmentLevel: AgencyFinalCommitmentLevel;
  reviewerAnswerNote:                  string;
  reviewerSelectedTemplate:            string;
  noteForAgency:                       string;
  reviewerRequirementAnswers:          any[];
  reviewerNoteTemplate:                string;
  reviewerNoteForAgency:               string;
  expertRequirementAnswers:            any[];
  expertNoteTemplate:                  string;
  expertNotContent:                    string;
  expertNoteForAgency:                 string;
  pocOfficerNoteTemplate:              string;
  pocOfficerNoteForAgency:             string;
  pocOfficerRequirementAnswers:        any[];
}

export interface AgencyFinalCommitmentLevel {
  surveyId:    number;
  title:       Label;
  color:       string;
  fixedDegree: number;
  rangeFrom:   number;
  rangeTo:     number;
  id:          number;
  label?:      Label;
  value?:      number;
}

export type Label = "التزام جزئي" | "التزام كلي" | "عدم التزام" | "لا يوجد اجابة" | "لا ينطبق ";

export interface ExpertRequirementsAnswer {
  title:                                  string;
  creationTime:                           Date;
  standardRequirementClassificationId:    number;
  standardRequirementClassificationTitle: string;
  recommendation:                         string;
  subStandardRequirements:                any[];
  agencyStandardRequirements:             null;
  standardProofRequirements:              null;
  id:                                     number;
  standardRequirementId:                  number;
  standardRequirementTitle:               string;
  decision:                               number;
  donotApply:                             boolean;
  standardRequirement:                    null;
  agencyRequirementProofAnswers:          null;
}

export interface StandardProof {
  title:                             string;
  standardProofTypeId:               number;
  standardProofTypeTitle:            string;
  fileCount:                         number;
  standardProofFileTypeAssociations: StandardProofFileTypeAssociation[];
  agencyStandardProofs:              null;
  standardProofRequirements:         StandardProofRequirement[];
  fileSize:                          number;
  standardProofFileSizeTypeId:       number;
  standardProofFileSizeTypeTitle:    string;
  isRequired:                        boolean;
  creationTime:                      Date;
  id:                                number;
  standardProofId:                   number;
  standardProofTitle:                string;
  standardProofFileSize:             number;
  standardProofAttachments:          StandardProofAttachment[];
  note:                              null;
  pastSelectedFiles:                 any[];
  reviewerProofNotes:                string;
}

export interface StandardProofAttachment {
  attachment:           Attachment;
  fromPastYear:         boolean;
  pageFrom:             null;
  pageTo:               null;
  relatedRequirementId: number;
  id:                   number;
}

export interface Attachment {
  name:                  string;
  uri:                   string;
  storedFileName:        string;
  folder:                string;
  isTrashed:             boolean;
  fileType:              null;
  entityType:            null;
  isEncrypted:           boolean;
  creationTime:          Date;
  isAgencyQiyas23Report: boolean;
  yearOfQiyas:           null;
  id:                    number;
}

export interface StandardProofFileTypeAssociation {
  standardProofFileTypeId:    number;
  standardProofFileTypeTitle: string;
  id:                         number;
}

export interface StandardProofRequirement {
  standardProofId:       number;
  standardRequirementId: number;
  standardProofTitle:    null;
  id:                    number;
}

export interface TemplateList {
  surveyId:                  number;
  correctorCommitmentLevels: CommitmentLevel[];
  agencyCommitmentLevels:    CommitmentLevel[];
  templateContent:           string;
  agencyHasAttachment:       null;
  id:                        number;
}

export interface CommitmentLevel {
  commitmentLevel: AgencyFinalCommitmentLevel;
  id:              number;
}

interface IProps {
  setMenuElement: (index: number) => void;
}

const NotesForAgency = ({ setMenuElement }: IProps) => {
  const { newReviewerAnswer,  reviewerAnswer: TEMP } = useAnswerContext();
  const reviewerAnswer = TEMP as IReviewerAnswer;

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={answer}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">الملاحظات</h1>
          </div>
        </div>

        <div className="w-[100%]  pr-[88px] flex flex-col  items-start overflow-y-auto h-[300px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات المدقق:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.reviewerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {reviewerAnswer?.reviewerRequirementAnswers?.map(
                (requirement) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement?.reviewerSubRequirementAnswers?.map(
                        (subRequirement: any) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.reviewerNotContent || "",
              }}
            ></div>
            {!reviewerAnswer?.reviewerNoteTemplate &&
              !reviewerAnswer?.reviewerAnswerNote &&
              !reviewerAnswer?.reviewerRequirementAnswers?.length && (
                <div>لا يوجد ملاحظات</div>
              )}
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الخبير:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.expertNoteTemplate,
              }}
            ></div>
            <ul className="px-2">
              {reviewerAnswer?.expertRequirementAnswers?.map(
                (requirement) => (
                  <li>
                    {showRequirement(requirement) ? requirement.title : ""}
                    <ul>
                      {requirement?.expertSubRequirementAnswers?.map(
                        (subRequirement: any) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">{subRequirement.title}</li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.expertNotContent || "",
              }}
            ></div>
            {!reviewerAnswer?.standardRequirements?.length &&
              !reviewerAnswer?.expertNoteTemplate &&
              !reviewerAnswer?.expertNotContent  && <div>لا يوجد ملاحظات</div>}
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات ضابط الاتصال:
            </h2>
            {/* <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertAnswer?.pocOfficerNoteForAgency || "لا يوجد ملاحظات",
              }}
            ></div> */}
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.pocOfficerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {reviewerAnswer?.pocOfficerRequirementAnswers?.map(
                (requirement) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement?.subStandardRequirements?.map(
                        (subRequirement: any) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.pocOfficerNotContent || "",
              }}
            ></div>
            {!reviewerAnswer?.pocOfficerNoteTemplate &&
              !newReviewerAnswer?.pocOfficerNotContent &&
              !reviewerAnswer?.standardRequirements?.length && (
                <div>لا يوجد ملاحظات</div>
              )}
          </div>
        </div>
      </div>
      <NextBtn onClick={() => setMenuElement(7)} />
    </>
  );
};

export default NotesForAgency;
