import React from "react";
import SCard from "./SCard";
import Skeleton from "react-skeleton-loader";

const DashboardStats = ({ cursorPointer, lists, loading, onClick }) => {
  return (
    <>
      {lists.length ? (
        <div
          className="m-5 py-5"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
            gap: "20px",
          }}
        >
          {lists.map((card, index) => (
            <SCard
              cursorPointer={cursorPointer}
              onClick={() => onClick(card.id)}
              key={index}
              icon={card.icon}
              title={
                loading ? (
                  <div className="flex flex-col items-start">
                    <Skeleton
                      width={"50px"}
                      height={"10px"}
                      color={card.color}
                    />
                  </div>
                ) : (
                  card.title
                )
              }
              loading={loading}
              color={card.color}
              number={card.number || 0}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default DashboardStats;
