import React from "react";
import answer from "./../../../../../../../assets/icons/answer.svg";
import NextBtn from "../../components/buttons/nextBtn";
import { useAnswerContext } from "../../answerContext";
import {
  isAdmin,
  isInquiryManager,
} from "../../../../../../../helpers/isPermission";
import { showRequirement } from "../utils";

const NotesForAgency = ({
  setMenuElement,
  expertManagerAnswerOnPhase2,
}) => {

  const reviewerNoteTemplate =
    expertManagerAnswerOnPhase2?.result?.lastAnswer?.reviewerNoteForAgency
      ?.noteTemplate?.templateContent || "";
  const reviewerRequirementAnswers = expertManagerAnswerOnPhase2?.result?.lastAnswer?.reviewerRequirementAnswers
  const reviewerNotContent = expertManagerAnswerOnPhase2?.result?.lastAnswer?.reviewerNoteForAgency?.content


  const expertNoteTemplate = expertManagerAnswerOnPhase2?.result?.lastAnswer?.expertNoteForAgency?.noteTemplate?.templateContent || ""
  const expertRequirementAnswers = expertManagerAnswerOnPhase2?.result?.lastAnswer.expertRequirementAnswers
  const expertNotContent = expertManagerAnswerOnPhase2?.result?.lastAnswer?.expertNoteForAgency?.content

  const pocOfficerNoteTemplate = expertManagerAnswerOnPhase2?.result?.lastAnswer?.pocOfficerNoteForAgency?.noteTemplate?.templateContent || ""
  const pocOfficerNotContent = expertManagerAnswerOnPhase2?.result?.lastAnswer?.pocOfficerNoteForAgency?.content
  const pocOfficerRequirementAnswers = expertManagerAnswerOnPhase2?.result?.lastAnswer.pocOfficerRequirementAnswers


  

  return (
    <>
      <div className="min-h-[400px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={answer}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">الملاحظات</h1>
          </div>
        </div>

        <div className="w-[100%]  pr-[88px] flex flex-col  items-start overflow-y-auto scrollbar-track-gray-100">
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات المدقق:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: reviewerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {reviewerRequirementAnswers?.map(
                (requirement, index) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement.reviewerSubRequirementAnswers.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: reviewerNotContent || "",
              }}
            ></div>
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الخبير:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: expertNoteTemplate,
              }}
            ></div>
            <ul className="px-2">
              {expertRequirementAnswers?.map(
                (requirement, index) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement.expertSubRequirementAnswers?.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: expertNotContent || "",
              }}
            ></div>
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات ضابط الاتصال:
            </h2>
            {/* <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertAnswer?.pocOfficerNoteForAgency || "لا يوجد ملاحظات",
              }}
            ></div> */}
            <div
              dangerouslySetInnerHTML={{
                __html: pocOfficerNoteTemplate || "",
              }}
            ></div>
            <ul className="px-2">
              {pocOfficerRequirementAnswers?.map(
                (requirement, index) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ""}
                    <ul>
                      {requirement.pocOfficerSubRequirementAnswers.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: pocOfficerNotContent || "",
              }}
            ></div>
            {!pocOfficerRequirementAnswers?.length &&
              !pocOfficerNoteTemplate &&
              !pocOfficerNotContent && (
                <div>لا يوجد ملاحظات</div>
              )}
          </div>
        </div>
      </div>
      {!isAdmin && !isInquiryManager && (
        <NextBtn onClick={() => setMenuElement(7)} />
      )}
    </>
  );
};

export default NotesForAgency;
