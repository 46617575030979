import { useAnswerContext } from "../../answerContext";
import StandardAnswerProofFiles from "../../standardAnswerProofFiles";
import ShowUploadedFiles from "../../standardAnswerProofFiles/ui/showUploadedFiles";

const ExpertProofFiles = ({
  setMenuElement,
  standardProofs: standardProofsProps,
}) => {
  const { newExpertAnswer } = useAnswerContext();

  const standardProofs = standardProofsProps ?? newExpertAnswer?.standardProofs;

  const renderedFiles = standardProofs?.map((file, index) => (
    <div
      key={index}
      className="max-w-[750px] pl-8 mb-8 pr-[80px] flex flex-col justify-beween items-start "
    >
      <div className="w-full bg-slate-50 rounded-md px-5 ">
        <div className="relative mt-4 flex flex-row items-start justify-between">
          <div className="flex items-start">
            <span className="bg-[#D2F2E6] w-4 h-4 rounded-full absolute flex justify-center items-center mt-2">
              <span className="bg-[#1CC081] w-[6px] h-[6px] rounded-full absolute"></span>
            </span>
            <h4 className="mr-6 ml-6 text-[#292069]">{file?.title}</h4>
          </div>
          <p className="text-[#7D8BAB] mb-2">{file?.fileSize + "MB"}</p>
        </div>
        <ShowUploadedFiles uploadedFiles={file?.standardProofAttachments} />
        {file.note && (
          <div className="mb-5 mt-4">
            <h3 className="font-semibold">ملاحظة الجهة: </h3>
            <p className="my-1">{file.note || "لا يوجد"}</p>
          </div>
        )}
      </div>
    </div>
  ));

  return (
    <StandardAnswerProofFiles setMenuElement={setMenuElement}>
      {renderedFiles}
    </StandardAnswerProofFiles>
  );
};

export default ExpertProofFiles;
