import { useQuery } from "@tanstack/react-query";
import environment from "../../../../services/environment";
import { axiosGetRequest } from "../../../../services/Request";
import { useContext } from "react";
import { StageContext } from "../../../../contexts/stageContext";
import { useAccountMangerDashboardContext } from "../../../../contexts/AccountMangerDashboardContext";

export const useGetAccountMangerAgencyStats = () => {
  const { stageForDashboard } = useContext(StageContext);
  const { selectedAgency } = useAccountMangerDashboardContext();

  const { currentStage } = useContext(StageContext)
  const phase2StageId = currentStage?.stageIds?.[1];

  const agencyId = selectedAgency?.value;
  const surveyStageId = localStorage.getItem("stageForDashboard");

  const { data, isLoading: isGettingDashboardStats } = useQuery({
    queryKey: ["useGetAccountMangerAgencyStats", stageForDashboard, agencyId],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgencyStatstics, {
        agencyId: agencyId,
        surveyStageId: stageForDashboard || surveyStageId || 0,
        stageId: phase2StageId
      }),
    enabled: !!(stageForDashboard || surveyStageId || 0)
  });

  const agencyStats = data?.result;

  return { data, agencyStats, isGettingDashboardStats };
};
