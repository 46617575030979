import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { axiosPostRequest } from "../../../services/Request";
import environment from "../../../services/environment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.font.family = "DiodrumArabic";

function VisitorsChart({ chartData }) {
  const options = {
    type: "bar",
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "عرض اٍحصائيات لاخر أسبوع",
        font: {
          size: 18,
          family: "DiodrumArabic", // Apply custom font
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        minmax: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },

    responsive: true,
  };

  const labels = chartData?.map((item) => item.day)?.reverse();

  const data = {
    labels,
    datasets: [
      {
        barPercentage: 1,
        label: "ممثلو الجهات",
        data: chartData?.map((item) => item.userCount)?.reverse(),
        backgroundColor: "#35ccb8",
      },
      {
        label: "الجهات",
        data: chartData?.map((item) => item.agencyCount)?.reverse(),
        backgroundColor: "#fccb2b",
      },
    ],
  };

  return <Bar data={data} options={options} />;
}

export default VisitorsChart;
